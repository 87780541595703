//import react from 'react'
//import { useEffect } from 'react';
//import {Socket} from 'socket.io-client';
import io from 'socket.io-client';
import gamepictwo from '../images/gamepictwo.jpg';
import authAxois from './requiestHandler';
import { Link, useParams, useNavigate} from 'react-router-dom';
//import userName from '../images/userName.jpg';
import glassOne from '../images/glassOne.jpg';
import userName from '../images/userName.jpg'
import phoneNumber from '../images/phoneNumber.jpg'
import '../styles/game.css';
import gamenum from '../images/gamenum.wav';
import win from '../images/win.mp3';
import gameim from '../images/gameim.jpg';
import bgame from '../images/bgame.jpg'
import register from '../images/register.png';
import gamepicfour from '../images/gamepicfour.jpg'
import facebook from '../images/facebook.jpg'
import email from '../images/email.jpg'
import linkedin from '../images/linkedin.jpg'
//import glassOne from '../images/glassOne.jpg';
//import axios from 'axios';
//import axios from 'axios'
//import manwinning1 from '../images/manwinning1.jpg'
import { useEffect } from 'react';

//const values=[]

//const socket=io.connect('https://bingobet.net',{
  //  transports:['polling','websocket']
//})
//const socket=io.connect('http://localhost:5000');
//const socket=io.connect('https://groupgame.bingobet.net')
//const socket=io.connect('https://game2free.bingobet.net')
const socket=io.connect('https://game.bingobet.net')
//const socket=io.connect('https://game2free.bingobet.net:443',{
 //   transports:['polling','websocket']
//})
/*
const socket=io.connect("https://game.bingobet.net",{
    transports:['polling','websocket'],
    path:"/home/game.bingobet.net"
})
*/
//setInterval(myTimer, 1000);
//function myTimer() {
    //var d = new Date();
    //document.getElementById("demo").innerHTML = d.toLocaleTimeString();
//}

//const bingoNumberr={B:[],I:[],N:[],G:[],O:[]}
//var wiinn=0
const bingonumbers=[]
const bingonumberss=[]
//const bingonumbersss=[]
//let gameon=0
let gamestate=0
let u=4,v=60;
let vall=0;
function Join(){
    const navigate=useNavigate()
    const {id}=useParams()
   
     useEffect(()=>{
        document.querySelector('.roundlevelchannel').style.display='block';
        const st=document.querySelector('.join')
        st.style.width='300px';
        st.style.position='fixed';
        st.style.marginTop='280px'
        st.style.height='300px';
        st.style.overflow='scroll'
        socket.on('channel',(dt)=>{
            
            const nt=document.createElement('p');
            nt.textContent=`Channel Name - ${dt.channelname}`
            nt.style.fontFamily='arial';
            nt.style.color='white'
            document.querySelector('.join').appendChild(nt)
            
            const kk=document.createElement('input')
            kk.setAttribute('class','con')
            kk.setAttribute('placeholder','password')
            kk.setAttribute('type','password')
           // kk.setAttribute('class','pwdd')
            kk.style.padding='8px';
            kk.style.width='200px'
            kk.style.border='1px solid gold'
            document.querySelector('.join').appendChild(kk)

            const btn=document.createElement('button')
            btn.textContent='connect'
            btn.style.padding='15px';
            btn.style.marginLeft='50px';
            btn.style.marginTop='10px';
            btn.style.backgroundColor='black';
            btn.style.width='200px';
            btn.style.borderRadius='20px'
            btn.style.cursor='pointer'
            btn.addEventListener('click',()=>{
              // alert('hello')
             
                /////////////////////////////////////////////////////////////////
                const info={
                    username:sessionStorage.getItem('userName'),
                    channelname:dt.channelname,
                    channelpassword:document.querySelector('.con').value

                }
                socket.emit('channel',info)
                document.querySelector('.roundlevelchannel').style.display='none'
               // document.querySelector('.plllist').innerHTML=`${sessionStorage.getItem('userName')}......channel name:${document.querySelector('.chname').value}.....password:${document.querySelector('.pasw').value}`
               document.querySelector('.plllist').innerHTML=''
             
               const pictur=document.createElement('img')
               pictur.setAttribute('src',`${glassOne}`)
               pictur.style.borderRadius='30px'
               pictur.style.padding='8px';
               pictur.style.width='40px';
               pictur.style.height='40px'
               document.querySelector('.plllist').appendChild(pictur)
                
               const namee=document.createElement('p');
               namee.textContent=`owner || \n${dt.username}`
               namee.style.color='gold'
               namee.style.marginLeft='60px';
               namee.style.marginTop='-50px'
               namee.style.fontFamily='arial'
               document.querySelector('.plllist').appendChild(namee)
        
               const dtt=document.createElement('p');
               dtt.textContent=`${new Date().toLocaleString()}`
               dtt.style.color='gray'
               dtt.style.marginLeft='60px';
               dtt.style.marginTop='-10px'
               dtt.style.fontFamily='arial'
               document.querySelector('.plllist').appendChild(dtt)
        
        
               const chan=document.createElement('p');
               chan.textContent=`channel Name - ${dt.channelname}`;
               chan.style.fontFamily='arial'
               chan.style.marginLeft='5px';
               chan.style.marginTop='5px';
               chan.style.color='#75085a'
               document.querySelector('.plllist').appendChild(chan) 
        
               const pwd=document.createElement('p');
               pwd.textContent=`channel password - ${document.querySelector('.con').value}`
               pwd.style.marginLeft='200px';
               pwd.style.marginTop='-27px';
               pwd.style.fontFamily='arial'
               pwd.style.color='green'
               document.querySelector('.plllist').appendChild(pwd)

              /////////////////////////////////////////////////////////////////////
                socket.emit('joinRoom',info)
                document.querySelector('.roundlevelchannel').style.display='none';
            })
            document.querySelector('.join').appendChild(btn)

            const brr=document.createElement('p')
            brr.textContent=''
            brr.style.width='300px'
            brr.style.borderBottom='3px solid #303030'
            document.querySelector('.join').appendChild(brr)   

        })
       // socket.on() 
       
     })
     
     
     
     const gcsn={b:[],i:[],n:[],g:[],o:[]} 
     const storedcartelanumber={b:[],i:[],n:[],g:[],o:[]}
     const userclick={b:[],i:[],n:[],g:[],o:[]}
     //const winningcard={b:[],i:[],n:[],g:[],o:[]}
   ////GAME START IN LOGIC

 //all winners from the server side
 //let kd=0;
 if(gamestate===0){
   
   let dg=setInterval(()=>{
         v--
         //console.log(v)
         if(v===0){
            v=60
            u--
         }
         if(u===-1){
            u=4
         }
         document.querySelector('.gamestart').innerHTML=`Game start in\n ${u}:${v}`
         //console.log(`${u}:${v}`)
         if(u===0){
            gamestate++;
            //kd=1;
            document.querySelector('.gamestart').innerHTML=`Be Ready\n Any two vertical close\n wins`
            clearInterval(dg)  
            ///////begining of random logic
           // let bingonumber=[];
          /*****   
            for(let i=0;i<=150;i++){
               var mk=Math.floor(Math.random()*75)+1;    
               bingonumber.push(mk);
             }
           /////////
           //bingonumb=bingonumber
              for(let d=0;d<bingonumber.length;d++){
                    // console.log(`number of itration of bingo nunber is ${bingonumber[d]}`)
                     bingonumbers.push(bingonumber[d])
                  }
           */
             // let man=0,woman=0;
                 
             
            
            ////end of the random logic
         }

    },1000)
  
    




    ////////////////////////////Begining of code logic
   

    /////////////////////////ending of bingo logic

 }
 
///////////////////////you have to write logice over her to display random numbers

///////////////////these will be the end of the random number logic




 socket.on('listenwinnerss',(info)=>{
    if(info.stat===id){
       v=60
       u=4  
      // gameon=1    
      // gamestate=1
   document.querySelector('.bingowin').remove()    
  setTimeout(()=>{     
    document.querySelector('.gamestart').style.display='block'   
    document.querySelector('.winnersbet').style.display='block'
    console.log(`winners info is comming from the server side with info of `)
    //console.log(info)
  },8000)
    const dwin=document.querySelector('.winnersbet')
    const bbt=document.createElement('button')
    bbt.textContent=`${info.game} \n Total Winners : ${info.amounts}`
    bbt.style.width='30px'
    bbt.style.height='30px'
    bbt.style.position='absolute'
    bbt.style.padding='10px'
    bbt.style.marginLeft='-100px'
    bbt.style.borderRadius='30px'
    bbt.style.border='none'
    bbt.style.backgroundColor='#303030'
    bbt.style.color='white'
    
  dwin.appendChild(bbt)

    let tnum1=Math.floor(Math.random()*9)+1
    let tnum2=Math.floor(Math.random()*9)+1
    let tnum3=Math.floor(Math.random()*9)+1
    let tnum4=Math.floor(Math.random()*9)+1

    const winname=document.createElement('button')
    winname.textContent=`${info.dk.name.slice(0,1)}`
    winname.style.backgroundColor=`#30${tnum1}${tnum2}${tnum3}${tnum4}`
    winname.style.width='30px'
    winname.style.height='30px'
    winname.style.border='none'
    winname.style.padding='5px'
    winname.style.color='white'
    winname.style.fontSize='15px'
    winname.style.borderRadius='20px'
    winname.style.margin='5px'
    dwin.appendChild(winname)
    
    const winnname=document.createElement('p')
    winnname.textContent=`${info.dk.name}`
    winnname.style.marginLeft='40px'
    winnname.style.marginTop='-40px'
    winnname.style.fontFamily='Arial, Helvetica, sans-serif'
    winnname.style.fontSize='15px'
    dwin.appendChild(winnname)
    
    const winphone=document.createElement('p')
    winphone.textContent=`${info.dk.phone.slice(0,8)}**`
    winphone.style.fontFamily='Arial, Helvetica, sans-serif'
    winphone.style.paddingLeft='50px'
    winphone.style.marginTop='-20px'
    winphone.style.color='yellow'
    winphone.style.marginTop='-10px'
    winphone.style.fontSize='10px'
    winphone.style.borderBottom='1px solid aqua'
    dwin.appendChild(winphone)

    const total=document.createElement('h2')
    total.textContent=`Number of total\nWinners :${info.amounts}`
    total.style.backgroundColor='#303030'
    total.style.padding='10px'
    total.style.borderRadius='10px'
    total.style.fontSize='10px'
    total.style.fontFamily='Arial, Helvetica, sans-serif'
    total.style.marginLeft='20px'
    total.style.color='white'
    dwin.appendChild(total)

   setTimeout(()=>{
    document.querySelector('.maincho').style.display='block'
    document.querySelector('.cho').style.display='block'
   },20000)
   
  
    
}else{
    document.querySelector('.winnersbet').style.display='none'
}

})



   socket.on('gamestartingtime',(gm)=>{

    if(gm){
        document.querySelector('.gamestart').innerHTML=`GAME OVER WITH WINNER OF\n` 
    }
   // document.querySelector('.gamestart').innerHTML=`ALL WINNERS \n\n ${gm}`
   const winne=document.querySelector('.gamestart')
   winne.style.width='500px'
   winne.style.height='300px';
   
   const winnerss=document.createElement('button')
   winnerss.textContent=gm.name.slice(0,2)
   winnerss.style.backgroundColor=`#156734`
   winnerss.style.width='30px'
   winnerss.style.height='30px';
   winnerss.style.borderRadius='30px'
   winnerss.style.border='none';
   winnerss.style.padding='10px'
   winnerss.style.justifyContent='center'
   winnerss.style.position='absolute'
   winnerss.style.fontFamily='arial'
   winnerss.style.marginLeft='-10px'
   winne.appendChild(winnerss)

   const nt=document.createElement('p')
   nt.textContent=gm.name
   nt.style.fontFamily='arial'
   nt.style.marginLeft='100px'
   nt.style.color='white'

   winne.appendChild(nt)

   const pt=document.createElement('p')
   pt.textContent=gm.phone
   pt.style.position='absolute'
   pt.style.marginLeft='200px'
   pt.style.marginTop='-50px'
   pt.style.fontFamily='arial'

   winne.appendChild(pt)
   //console.log(gm)
    
   })

   socket.on('winnerflag',(dtk)=>{
    document.querySelector('.rdnumber').style.display='none'
   })

///////////////////////////////////////////////////////The active persone/////////////////////
  
    
   
//////////////////////////////////////////////////////////////end of active person
      
    /////////////////now the magic will happen
  /*  
    
   */ 
    ///////////////////the magic has problems
    
    socket.on('activePlayers',(data)=>{
        //console.log(data)
        //const dt=data.username
        //dt.splice(0,1)

        const im=document.createElement('button');
        //im.setAttribute('src',`${userName}`)
        const t=Math.floor(Math.random()*9)+1
        const k=Math.floor(Math.random()*9)+1
        im.textContent=`${data.username.slice(0,2)}`
        im.style.justifyItems='center'
        im.style.margin='15px';
        im.style.fontSize='20px'

        //im.style.padding='10px';
        im.style.border='none' 
        im.style.backgroundColor=`#1${t}${k}5${t}5`;
        im.style.color='white';
        im.style.width='50px';
        im.style.height='50px';
        im.style.padding='10px';
        im.style.borderRadius='30px';
        //im.style.position='absolute';
        //im.style.display='none'
        for(let k=-50;k<50;k++){
            setTimeout(()=>{
                for(let n=-50;n<k;n++){
                    im.style.marginLeft=`${n}px`
                }
            })
        }
        document.querySelector('.playersInfo').appendChild(im)

        const name=document.createElement('p')
        name.textContent=data.username;
        name.style.fontFamily=' Arial, Helvetica, sans-serif';
       // name.style.marginLeft='70px';
       name.style.position='absolute'
        name.style.marginTop='-60px';
        name.style.fontSize='15px';
        name.style.color='yellow';
        
        for(let k=-50;k<=110;k++){
           
            setTimeout(()=>{
                for(let n=-50;n<k;n++){
                    name.style.marginLeft=`${n}px`
                }
            })
        }
        //name.scrollTop =5000;
        //name.documentElement.scrollTop =5000;
       
        document.querySelector('.playersInfo').appendChild(name);

        const br=document.createElement('br')
        document.querySelector('.playersInfo').appendChild(br)
        const bd=document.createElement('br')
        document.querySelector('.playersInfo').appendChild(bd)
        const heigh=document.querySelector('.playersInfo').clientHeight
        document.querySelector('.playersInfo').scroll(0,heigh) 
        //now create p that show amount of birr
     /*  
        const birr=document.createElement('p')
        birr.textContent=`${Params.id} Birr`;
        birr.style.marginLeft='150px';
        birr.style.marginTop='-80px';
        document.querySelector('.playersInfo').appendChild(birr)
      */
        //now create button that show number of cards
      const bbt=document.createElement('button');
      bbt.textContent='Detaile';
      bbt.style.width='200px';
      bbt.style.marginLeft='10px';
      bbt.style.marginTop='-20px';
      bbt.style.position='absolute';
      bbt.style.backgroundColor='#151515';
      bbt.style.backgroundColor='black'
      bbt.style.color='white';
      bbt.style.border='1px solid yellow'
      bbt.style.padding='10px';
      bbt.style.borderRadius='10px';
      //bbt.style.border='none';
      bbt.style.cursor='pointer'
      //now make animation
      for(let k=-50;k<50;k++){
        setTimeout(()=>{
            for(let n=-50;n<k;n++){
                bbt.style.marginLeft=`${n}px`
            }
        })
    } 
      bbt.addEventListener('click',()=>{
         //console.log(data.cardNumber)
         document.querySelector('.detail').style.display='block';
     /*    
     for(let i=-250;i<0;i++){
        setTimeout(()=>{
            for(let k=-250;k<i;k++){
                document.querySelector('.detail').style.marginLeft=`${k}px`;
            }
        })
     }
     */
         const dim=document.createElement('img');
         dim.setAttribute('src',`${userName}`)
         dim.style.width='50px';
         dim.style.position='absolute';
         dim.style.height='50px';
         dim.style.marginTop='50px';
         dim.style.borderRadius='30px';
         document.querySelector('.userdetail').appendChild(dim)

         const dname=document.createElement('p')
         dname.textContent=data.username;
         dname.style.position='absolute';
         dname.style.width='100px';
         dname.style.padding='10px'
         dname.style.backgroundColor='rgb(151, 16, 99)'
         dname.style.color='white';
         dname.style.fontSize='10px';
         dname.style.fontFamily='arial';
         dname.style.marginTop='50px'
         dname.style.marginLeft='60px'
         document.querySelector('.userdetail').appendChild(dname)

         const dphone=document.createElement('p')
         dphone.textContent=data.phone;
         dphone.style.position='absolute';
         dphone.style.width='100px';
         dphone.style.fontSize='10px';
         dphone.style.padding='10px';
         dphone.style.fontFamily='arial';
         dphone.style.backgroundColor='black';
         dphone.style.color='white';
         dphone.style.marginTop='90px';
         dphone.style.marginLeft='60px'
         document.querySelector('.userdetail').appendChild(dphone)

    const cardinfo=document.createElement('h4')
    cardinfo.textContent='Cartela Number'
    cardinfo.style.position='fixed';
    cardinfo.style.backgroundColor='black';
    cardinfo.style.width='160px';
    cardinfo.style.marginTop='130px';
    cardinfo.style.marginLeft='10px';
    cardinfo.style.fontFamily='arial';
    cardinfo.style.padding='10px'
    cardinfo.style.fontSize='15px';
    document.querySelector('.userdetail').appendChild(cardinfo)

//now add user card number
const bingoo=['B','I','N','G','O']
//const cardsec=document.querySelector('.cardsec') 
  
//const addbin=document.querySelector('.bin')
for(let g=0;g<bingoo.length;g++){
const b=document.createElement('p')
b.textContent=bingoo[g];
b.style.backgroundColor='hsl(254, 52%, 26%)';
b.style.position='fixed';
b.style.width='10px';
b.style.fontSize='10px';
b.style.textAlign='center';
b.style.marginLeft=`${40*g}px`;
b.style.marginTop='170px';
b.style.padding='10px';
b.style.color='white';
b.style.borderRadius='20px'
document.querySelector('.userdetail').appendChild(b)



//now for card numbers
for(let k=0;k<5;k++){
    const B=document.createElement('button')
    B.textContent=data.cardNumber.B[k];
    B.style.backgroundColor='black';
    B.style.width='30px';
    B.style.fontSize='15px';
    B.style.position='absolute';
    B.style.textAlign='center';
    B.style.margin='10px 50px 50px 3px';
    B.style.border='1px solid yellow'
    B.style.padding='5px';
    B.style.cursor='pointer';
    B.style.color='white';
    B.style.borderRadius='10px'
    
    B.addEventListener('mouseover',()=>{
        B.style.paddingLeft='300px';
        B.style.border='2px solid rgb(152, 9, 126)';
    })
    B.addEventListener('mouseleave',()=>{
        B.style.padding='10px';
        B.style.border='1px solid yellow'
    })
    
    document.querySelector('.userdetaill').appendChild(B)
    
    const br=document.createElement('br')
    document.querySelector('.userdetaill').appendChild(br)

}

for(let k=0;k<5;k++){
     const ct=document.createElement('br')

    const I=document.createElement('button')
    I.textContent=data.cardNumber.I[k];
    I.style.backgroundColor='black';
    I.style.width='30px';
    I.style.fontSize='15px';
    I.style.padding='20px';
    I.style.textAlign='center';
    //I.style.marginLeft='50px';
    I.style.position='absolute';
    I.style.margin='-80px 50px 50px 40px'
    I.style.border='1px solid yellow'
    I.style.padding='5px';
    I.style.cursor='pointer';
    I.style.color='white';
    I.style.borderRadius='10px'
    I.appendChild(ct)
  

    I.addEventListener('mouseover',()=>{
        I.style.paddingLeft='220px';
        I.style.border='2px solid rgb(152, 9, 126)';
    })
    I.addEventListener('mouseleave',()=>{
        I.style.padding='10px';
        I.style.border='1px solid yellow'
    })
    document.querySelector('.userdetaill').appendChild(I)
    
    const br=document.createElement('br')
    document.querySelector('.userdetaill').appendChild(br)

}

for(let k=0;k<5;k++){
    const N=document.createElement('button')
    N.textContent=data.cardNumber.N[k];
    N.style.backgroundColor='black';
    N.style.width='30px';
    N.style.fontSize='15px';
    N.style.position='absolute';
    N.style.textAlign='center';
    N.style.margin='-170px 50px 50px 75px';
    //N.style.marginLeft='100px'
    N.style.border='1px solid yellow'
    N.style.padding='5px';
    N.style.cursor='pointer';
    N.style.color='white';
    N.style.borderRadius='10px'
   

    N.addEventListener('mouseover',()=>{
        N.style.paddingLeft='170px';
        N.style.border='2px solid rgb(152, 9, 126)';
    })
    N.addEventListener('mouseleave',()=>{
        N.style.padding='10px';
        N.style.border='1px solid yellow'
    })
    document.querySelector('.userdetaill').appendChild(N)
    
    const br=document.createElement('br')
    document.querySelector('.userdetaill').appendChild(br)

}

for(let k=0;k<5;k++){
    const G=document.createElement('button')
    G.textContent=data.cardNumber.G[k];
    G.style.backgroundColor='black';
    G.style.width='30px';
    G.style.fontSize='15px';
    G.style.position='absolute';
    G.style.textAlign='center';
    G.style.margin='-260px 50px 50px 120px';
    //G.style.marginLeft='150px'
    G.style.border='1px solid yellow'
    G.style.padding='5px';
    G.style.cursor='pointer';
    G.style.color='white';
    G.style.borderRadius='10px'
  
    G.addEventListener('mouseover',()=>{
        G.style.paddingLeft='120px';
        G.style.border='2px solid rgb(152, 9, 126)';
    })
    G.addEventListener('mouseleave',()=>{
        G.style.padding='10px';
        G.style.border='1px solid yellow'
    })
    document.querySelector('.userdetaill').appendChild(G)
    
    const br=document.createElement('br')
    document.querySelector('.userdetaill').appendChild(br)

}   

for(let k=0;k<5;k++){
    const O=document.createElement('button')
    const p=document.createElement('p')
    p.textContent='p';

    O.textContent=data.cardNumber.O[k];
    O.style.backgroundColor='black';
    O.style.width='30px';
    O.style.fontSize='15px';
    O.style.position='absolute';
    O.style.textAlign='center';
    
    O.style.margin='-350px 0px 0px 150px';
    //O.style.marginLeft='200px'
    O.style.border='1px solid yellow'
    O.style.padding='5px';
    O.style.cursor='pointer';
    O.style.color='white';
    O.style.borderRadius='10px'
   
    O.addEventListener('mouseover',()=>{
        O.style.paddingLeft='120px';
        O.style.border='2px solid rgb(152, 9, 126)';
    })
    O.addEventListener('mouseleave',()=>{
        O.style.padding='10px';
        O.style.border='1px solid yellow'
    })
   
    document.querySelector('.userdetaill').appendChild(O)
    
    const br=document.createElement('br')
    document.querySelector('.userdetaill').appendChild(br)
    
  
}


}




      })
      document.querySelector('.playersInfo').appendChild(bbt);

      const brr=document.createElement('br')
        document.querySelector('.playersInfo').appendChild(brr)
   
     

      //now creat div element in side playerInfo div
       
        


    })    
     
    let clk=0;
    
   // useEffect(()=>{
        const playerInfo={
            playerName:sessionStorage.getItem('userName'),
            playerPhone:sessionStorage.getItem('phone')
           };
          
           authAxois.post('/user/player/game/10',playerInfo).then((res)=>{
           //axios.get('https://game.bingobet.net/user/player/game/10').then((res)=>{ 
           //axios.get("https://game2free.bingobet.net/user/card").then((res)=>{
          console.log('player game response is comming.....') 
             const allCards=JSON.parse(res.data)
             //console.log(JSON.parse(res.data))
            //const gk=allCards.card2
            const arrayofcards=["card1","card2","card3","card4","card5","card6","card7","card8","card9","card10","card11","card12","card13","card14","card15","card16","card17","card18","card19","card20","card21","card22","card23","card24","card25","card26","card27","card28","card29","card30"]
           
              
            const one=document.querySelector('.cardList');
           // const two=document.querySelector('.selectecard')
   for(let t=0;t<=30;t++){             
            
            const b=document.createElement('p');
            b.textContent="B";
            b.style.backgroundColor='hsl(274, 92%, 46%)';
            b.style.width='20px';
            b.style.textAlign='center';
            b.style.marginLeft='1px';
            b.style.padding='10px';
            b.style.color='white';
            b.style.borderRadius='20px'
            one.appendChild(b)
            one.style.backgroundColor='#1f1f1d';
            one.style.width='250px'
            //try to add values in to b
            for(let v=0;v<5;v++){
                //one.appendChild(document.createElement('').textContent=`${allCards.card1.B[v]}`)
               const a=document.createElement('button');
               a.textContent=allCards[arrayofcards[t]].B[v];
               a.style.backgroundColor='black';
               a.style.borderRadius='20px';
               //a.style.border='1px solid yellow'
               a.style.color='white'
               a.style.cursor='pointer';
               a.style.margin='1px';
               a.style.width='35px'
               one.appendChild(a)
               const br=document.createElement('br')
               one.appendChild(br)
            }
            
         
             

            const i=document.createElement('p');
            i.textContent="I";
            i.style.backgroundColor='hsl(274, 92%, 46%)';
            i.style.width='20px';
            i.style.padding='10px';
            i.style.marginLeft='50px';
            i.style.textAlign='center';
            i.style.marginTop='-235px';
            i.style.color='white';
            i.style.borderRadius='20px'
          
            one.appendChild(i) 
            one.style.backgroundColor='#1f1f1d';
            one.style.width='250px'
            for(let v=0;v<5;v++){
                //one.appendChild(document.createElement('').textContent=`${allCards.card1.B[v]}`)
               const a=document.createElement('button');
               a.textContent=allCards[arrayofcards[t]].I[v];
               a.style.backgroundColor='black';
               a.style.borderRadius='20px';
               a.style.cursor='pointer'
               a.style.marginLeft="50px";
               a.style.marginTop='-305px'
               //a.style.border='1px solid yellow'
               a.style.color='white'
             
               one.appendChild(a)
               const br=document.createElement('br')
               one.appendChild(br)
            }

            const n=document.createElement('p');
            n.textContent="N";
            n.style.backgroundColor='hsl(274, 92%, 46%)';
            n.style.width='20px';
            n.style.padding='10px';
            n.style.marginLeft='100px';
            n.style.textAlign='center';
            n.style.marginTop='-240px';
            n.style.color='white';
            n.style.borderRadius='20px'
            one.appendChild(n)
            one.style.backgroundColor='#1f1f1d';
            one.style.width='250px'
            for(let v=0;v<5;v++){
                //one.appendChild(document.createElement('').textContent=`${allCards.card1.B[v]}`)
               const a=document.createElement('button');
               a.textContent=allCards[arrayofcards[t]].N[v];
               a.style.backgroundColor='black';
               a.style.borderRadius='20px';
               a.style.cursor='pointer'
               a.style.marginLeft="100px";
               a.style.marginTop='-305px'
               //a.style.border='1px solid yellow'
               a.style.color='white'
               one.appendChild(a)

               const br=document.createElement('br')
               one.appendChild(br)
            }


            const g=document.createElement('p');
            g.textContent="G";
            g.style.backgroundColor='hsl(274, 92%, 46%)';
            g.style.width='20px';
            g.style.padding='10px';
            g.style.marginLeft='150px';
            g.style.textAlign='center';
            g.style.marginTop='-240px';
            g.style.color='white';
            g.style.borderRadius='20px'
            one.appendChild(g) 
            one.style.backgroundColor='#1f1f1d';
            one.style.width='250px'
            for(let v=0;v<5;v++){
                //one.appendChild(document.createElement('').textContent=`${allCards.card1.B[v]}`)
               const a=document.createElement('button');
               a.textContent=allCards[arrayofcards[t]].G[v];
               a.style.backgroundColor='black';
               a.style.borderRadius='20px';
               a.style.cursor='pointer'
               a.style.marginLeft="150px";
               a.style.marginTop='-200px'
               //a.style.border='1px solid yellow'
               a.style.color='white'
               one.appendChild(a)

               const br=document.createElement('br')
               one.appendChild(br)
            }

            const o=document.createElement('p');
            o.textContent="O";
            o.style.backgroundColor='hsl(274, 92%, 46%)';
            o.style.width='20px';
            o.style.padding='10px';
            o.style.marginLeft='200px';
            o.style.textAlign='center';
            o.style.marginTop='-240px';
            o.style.color='white';
            o.style.borderRadius='20px'
            
            one.appendChild(o) 
            one.style.backgroundColor='#1f1f1d';
            one.style.width='250px'
            for(let v=0;v<5;v++){
                //one.appendChild(document.createElement('').textContent=`${allCards.card1.B[v]}`)
               const a=document.createElement('button');
               a.textContent=allCards[arrayofcards[t]].O[v];
               a.style.backgroundColor='black';
               a.style.borderRadius='20px';
               a.style.cursor='pointer'
               a.style.marginLeft="200px";
               a.style.marginTop='-500px'
               //a.style.border='1px solid yellow'
               a.style.color='white'
               one.appendChild(a)
               if(v===4){
                const btn=document.createElement('button')
                btn.setAttribute('class','cardbutton')
                btn.textContent=`SELECT AND PLAY WITH ${arrayofcards[t]}`;
                btn.style.width='220px';
                btn.style.borderRadius='10px';
                btn.style.cursor='pointer'
                btn.style.backgroundColor='rgb(255, 0, 179)';
                btn.style.color='white';
                btn.border='none'
                //const aval=0;
                btn.addEventListener('click',()=>{
                  //  aval++;
                    //axios.get('https://game.bingobet.net/user/game/number')
                    socket.emit('exactplayers',sessionStorage.getItem('phone'))
                    document.querySelector('.cardsection').classList.add('button--loading')
                    document.querySelector('.cardbutton').classList.add('loading')
                    btn.style.backgroundColor='red';
                    btn.style.color='white';
                    const userInfo={
                        name:sessionStorage.getItem('userName'),
                        phone:sessionStorage.getItem('phone'),
                        cardName:allCards[arrayofcards[t]].cardName,
                        selectedcard:allCards[arrayofcards[t]]
                    }
                   // console.log('these is the card before sending to the server')
                   // console.log(userInfo)
                    //console.log(allCards.selectedcard.cardName)
                    //////////////BROTHER LOOK THE LOGIC/////////
                    //
                    //
                    //const {id}=useParams()
                    //alert(`Have a nice game\n Be sure you want\n ${id}Birr Medeb Game`)
                    const sid=id


////////////////////////these is binging of logic with out server///////////////////////////////////////////
/***  


*/
///////////////////////////////////////////////////////////////////end with out server

////////////////////////stope the client send the cards

authAxois.post(`/user/player/game/card/selection/${id}`,userInfo).then((res)=>{
    //axios.post(`https://groupgame.bingobet.net/user/player/game/card/selection/:${id}`,userInfo).then((res)=>{  
   console.log('selection card is send and the server send below response')
        //console.log(res.data)
        if(res.data.status==="34x"){
            alert(res.data.message)
        }
else{
        //creating and cheaking socket io server;
      /////#####################################
      /////############ start the client #######
      ////######################################
      
      socket.on('activebingoNumber',(bingonumber)=>{
        console.log('bingo number is comming from the server')
        //gamestate=0
        document.querySelector('.gamestart').style.display='none'
        //bingonumbers.push(bingonumber)
      //  console.log(`the calling bingo number is ${bingonumber}`)
   ///////now try to store the called numbers
 //###########################
 //
 //  THE CLINET BINGO NUBER LOGIC IS OVER HERE
 //
 //#########################  


 ////if the socket io connection is refused than play locally
 /** ****
 let bingonumb=[];
 for(let i=0;i<=150;i++){
    var mk=Math.floor(Math.random()*75)+1;    
    bingonumb.push(mk);
  }
  ****/
/////////
//bingonumb=bingonumber
if(bingonumbers.length===0){
    //let kt=[]
   for(let d=0;d<bingonumber.length;d++){
         // console.log(`number of itration of bingo nunber is ${bingonumber[d]}`
         bingonumbers.push(bingonumber[d])
         //console.log('first insertion of bingo number...')
         //console.log(bingonumbers.length)
         //let bingonum=[...new Set(bingonumbers)] 
       }
       //console.log([...new Set(bingonumbers)])
       bingonumberss.push([...new Set(bingonumbers)])
    
   let man=0,woman=0;
      
   setInterval(()=>{
        man++;
        
          if(man===6){  
              const dd=document.querySelector('.rdnumber')
              const apd=document.createElement('p')
              apd.setAttribute('class','sud')
              apd.textContent=bingonumberss[0][woman]
              apd.style.position='absolute'
              apd.style.backgroundColor='rgb(60, 5, 87)';
              apd.style.padding='10px';
              apd.style.marginTop='-6px';
              apd.style.border="none"
              apd.style.borderRadius='30px'
              dd.appendChild(apd)
              man=0   

      

       /////////////these is how the magic happens
    if(bingonumberss[0][woman]<=15){
        gcsn.b.push(bingonumberss[0][woman])
        const game=document.createElement('p')
        game.textContent=bingonumberss[0][woman]
        game.style.marginLeft='10px';
        game.style.marginTop='50px';
        game.style.marginBottom='-40px';
        game.style.backgroundColor='green';
        game.style.position='absolute'
        game.style.justifyContent='center'
        game.style.width='20px';
        game.style.height='20px';
        game.style.padding='5px'
        game.style.border='1px solid yellow';
        game.style.borderRadius='20px';
        document.querySelector('.b').appendChild(game)
        
    }
    
    else if(bingonumberss[0][woman]>15 && bingonumberss[0][woman]<=30){
        gcsn.i.push(bingonumberss[0][woman])
        const game=document.createElement('p')
        game.textContent=bingonumberss[0][woman]
        game.style.marginLeft='60px';
        game.style.marginTop='50px';
        game.style.position='absolute'
        game.style.marginBottom='-40px';
        game.style.backgroundColor='green';
        game.style.justifyContent='center'
        game.style.width='20px';
        game.style.height='20px';
        game.style.padding='5px'
        game.style.border='1px solid yellow';
        game.style.borderRadius='20px';
        document.querySelector('.i').appendChild(game)
       
    }
    else if(bingonumberss[0][woman]>30 && bingonumberss[0][woman]<=45){
        gcsn.n.push(bingonumberss[0][woman])
        const game=document.createElement('p')
        game.textContent=bingonumberss[0][woman]
        game.style.marginLeft='110px';
        game.style.marginTop='50px';
        game.style.position='absolute'
        game.style.marginBottom='-40px';
        game.style.backgroundColor='green';
        game.style.justifyContent='center'
        game.style.width='20px';
        game.style.height='20px';
        game.style.padding='5px'
        game.style.border='1px solid yellow';
        game.style.borderRadius='20px';
        document.querySelector('.n').appendChild(game)
    }
    else if(bingonumberss[0][woman]>45 && bingonumberss[0][woman]<=60){
        gcsn.g.push(bingonumberss[0][woman])
        const game=document.createElement('p')
        game.textContent=bingonumberss[0][woman]
        game.style.marginLeft='160px';
        game.style.position='absolute'
        game.style.marginTop='50px';
        game.style.marginBottom='-40px';
        game.style.backgroundColor='green';
        game.style.justifyContent='center'
        game.style.width='20px';
        game.style.height='20px';
        game.style.padding='5px'
        game.style.border='1px solid yellow';
        game.style.borderRadius='20px';
        document.querySelector('.g').appendChild(game)
    }
    else if(bingonumberss[0][woman]>60 && bingonumberss[0][woman]<=75){
        gcsn.o.push(bingonumberss[0][woman])
        const game=document.createElement('p')
        game.textContent=bingonumberss[0][woman]
        game.style.marginLeft='210px';
        game.style.marginTop='50px';
        game.style.marginBottom='-40px';
        game.style.position='absolute'
        game.style.backgroundColor='green';
        game.style.justifyContent='center'
        game.style.width='20px';
        game.style.height='20px';
        game.style.padding='5px'
        game.style.border='1px solid yellow';
        game.style.borderRadius='20px';
        document.querySelector('.o').appendChild(game)
    }else{
        console.log('invalid number ')
        document.querySelector('.gamestart').innerHTML=`Game Over\n` 
    }

     
       ///////////////the is the end of the magic
       
 if(document.querySelector('.sud').innerHTML===bingonumber){
        console.log(`no sound just ${document.querySelector('.sud').innerHTML}`)
    }else{
        const soundEffect=document.querySelector('.audioEl')
        soundEffect.play()
        woman++
        man=0
    }
          }
        
        },1000)

/////////////////////////////////////
   }

    
   
    });
 

      ////####################################
      ///################ end of the client ##
      //######################################
        const data={
            username:sessionStorage.getItem('userName'),
            phone:sessionStorage.getItem('phone'),
            cardNumber:allCards[arrayofcards[t]],
            medeb:id 
        }
        socket.emit('clientgamenumber',data)
        socket.emit('numberOfPlayers',data)
        document.querySelector('.cardsection').classList.remove('button--loading')
        document.querySelector('.cardbutton').classList.remove('loading') 
    const bingo=['B','I','N','G','O']
        const cardsec=document.querySelector('.cardsec') 
        //cardsec.style.backgroundColor='rgb(11, 9, 9)'  
        const addbin=document.querySelector('.bin')
    for(let g=0;g<bingo.length;g++){
        const b=document.createElement('p')
        b.textContent=bingo[g];
        b.style.backgroundColor='hsl(254, 52%, 26%)';
        b.style.position='fixed';
        b.style.width='20px';
        b.style.textAlign='center';
        b.style.marginLeft=`${50*g}px`;
       
        b.style.padding='10px';
        b.style.color='white';
        b.style.borderRadius='20px'
        addbin.appendChild(b)
    }
    
        for(let k=0;k<5;k++){
            storedcartelanumber.b.push(res.data.B[k])
            const B=document.createElement('button')
            B.textContent=res.data.B[k];
            B.style.backgroundColor='black';
            B.style.width='40px';
            B.style.position='absolute';
            B.style.padding='20px'
            B.style.textAlign='center';
            B.style.margin='10px 50px 50px 0px';
            B.style.border='1px solid yellow'
            B.style.padding='10px';
            B.style.cursor='pointer';
            B.style.color='white';
            B.style.borderRadius='20px'
            //B.style.marginTop='50px';
            let bgk=0
            B.addEventListener('click',()=>{
                if(bgk===0){
                    B.style.backgroundColor='rgb(8, 123, 89)';
                    bgk++
                    userclick.b.push(res.data.B[k])
                    }
                    else{
                    B.style.backgroundColor='black'
                    bgk=0
                       for(let m=0;m<userclick.b.length;m++){
                        if(userclick.b[m]===res.data.B[k]){
                            delete userclick.b[m]
                        }
                       }
                    }
            })
            B.addEventListener('mouseover',()=>{
                B.style.paddingLeft='300px';
                B.style.border='2px solid rgb(152, 9, 126)';
            })
            B.addEventListener('mouseleave',()=>{
                B.style.padding='10px';
                B.style.border='1px solid yellow'
            })
            
            cardsec.appendChild(B)
            
            const br=document.createElement('br')
            cardsec.appendChild(br)

            const brr=document.createElement('br')
            cardsec.appendChild(brr)

        }

        for(let k=0;k<5;k++){
            storedcartelanumber.i.push(res.data.I[k])
             const ct=document.createElement('br')

            const I=document.createElement('button')
            I.textContent=res.data.I[k];
            I.style.backgroundColor='black';
            I.style.width='40px';
            I.style.padding='20px';
            I.style.textAlign='center';
            //I.style.marginLeft='50px';
            I.style.position='absolute';
            I.style.margin='-170px 50px 50px 50px'
            I.style.border='1px solid yellow'
            I.style.padding='10px';
            I.style.cursor='pointer';
            I.style.color='white';
            I.style.borderRadius='20px'
            //I.style.marginTop='50px';
            I.appendChild(ct)
            let bgk=0
            I.addEventListener('click',()=>{
                if(bgk===0){
                    I.style.backgroundColor='rgb(8, 123, 89)';
                    bgk++
                    userclick.i.push(res.data.I[k])
                    }
                    else{
                    I.style.backgroundColor='black'
                    bgk=0
                    for(let m=0;m<userclick.i.length;m++){
                        if(userclick.i[m]===res.data.I[k]){
                            delete userclick.i[m]
                        }
                       }
                    }
            })

            I.addEventListener('mouseover',()=>{
                I.style.paddingLeft='220px';
                I.style.border='2px solid rgb(152, 9, 126)';
            })
            I.addEventListener('mouseleave',()=>{
                I.style.padding='10px';
                I.style.border='1px solid yellow'
            })
            cardsec.appendChild(I)
            
            const br=document.createElement('br')
            cardsec.appendChild(br)

            const brr=document.createElement('br')
            cardsec.appendChild(brr)

        }

        for(let k=0;k<5;k++){
            storedcartelanumber.n.push(res.data.N[k])
            const N=document.createElement('button')
            N.textContent=res.data.N[k];
            N.style.backgroundColor='black';
            N.style.width='40px';
            N.style.position='absolute';
            N.style.textAlign='center';
            N.style.margin='-350px 50px 50px 100px';
            //N.style.marginLeft='100px'
            N.style.border='1px solid yellow'
            N.style.padding='10px';
            N.style.cursor='pointer';
            N.style.color='white';
            N.style.borderRadius='20px'
            //N.style.marginTop='50px';
            let bgk=0
            N.addEventListener('click',()=>{
                if(bgk===0){
                    N.style.backgroundColor='rgb(8, 123, 89)';
                    bgk++
                    userclick.n.push(res.data.N[k])
                    }
                    else{
                    N.style.backgroundColor='black'
                    bgk=0
                    for(let m=0;m<userclick.n.length;m++){
                        if(userclick.n[m]===res.data.N[k]){
                            delete userclick.n[m]
                        }
                       }
                    }
            })

            N.addEventListener('mouseover',()=>{
                N.style.paddingLeft='170px';
                N.style.border='2px solid rgb(152, 9, 126)';
            })
            N.addEventListener('mouseleave',()=>{
                N.style.padding='10px';
                N.style.border='1px solid yellow'
            })
            cardsec.appendChild(N)
            
            const br=document.createElement('br')
            cardsec.appendChild(br)

            const brr=document.createElement('br')
            cardsec.appendChild(brr)

        }

        for(let k=0;k<5;k++){
            storedcartelanumber.g.push(res.data.G[k])
            const G=document.createElement('button')
            G.textContent=res.data.G[k];
            G.style.backgroundColor='black';
            G.style.width='40px';
            G.style.position='absolute';
            G.style.textAlign='center';
            G.style.margin='-530px 50px 50px 150px';
            //G.style.marginLeft='150px'
            G.style.border='1px solid yellow'
            G.style.padding='10px';
            G.style.cursor='pointer';
            G.style.color='white';
            G.style.borderRadius='20px'
            //G.style.marginTop='50px';
            let bgk=0
            G.addEventListener('click',()=>{
                if(bgk===0){
                    G.style.backgroundColor='rgb(8, 123, 89)';
                    bgk++
                    userclick.g.push(res.data.G[k])
                    }
                    else{
                    G.style.backgroundColor='black'
                    bgk=0
                    for(let m=0;m<userclick.g.length;m++){
                        if(userclick.g[m]===res.data.G[k]){
                            delete userclick.g[m]
                        }
                       }
                    }
            })
            G.addEventListener('mouseover',()=>{
                G.style.paddingLeft='120px';
                G.style.border='2px solid rgb(152, 9, 126)';
            })
            G.addEventListener('mouseleave',()=>{
                G.style.padding='10px';
                G.style.border='1px solid yellow'
            })
            cardsec.appendChild(G)
            
            const br=document.createElement('br')
            cardsec.appendChild(br)

            const brr=document.createElement('br')
            cardsec.appendChild(brr)

        }   

        for(let k=0;k<5;k++){
            storedcartelanumber.o.push(res.data.O[k])
            const O=document.createElement('button')
            const p=document.createElement('p')
            p.textContent='p';

            O.textContent=res.data.O[k];
            O.style.backgroundColor='black';
            O.style.width='40px';
            
            O.style.position='absolute';
            O.style.textAlign='center';
            
            O.style.margin='-715px 50px 50px 200px';
            //O.style.marginLeft='200px'
            O.style.border='1px solid yellow'
            O.style.padding='10px';
            O.style.cursor='pointer';
            O.style.color='white';
            O.style.borderRadius='20px'
           // O.style.marginTop='50px';
            let bgk=0
            O.addEventListener('click',()=>{
                if(bgk===0){
                    O.style.backgroundColor='rgb(8, 123, 89)';
                    bgk++
                    userclick.o.push(res.data.O[k])
                    }
                    ///////////////////////////////
                    ////////////////
                    ////////////
                    else{
                    O.style.backgroundColor='black'
                    bgk=0
                    for(let m=0;m<userclick.o.length;m++){
                        if(userclick.o[m]===res.data.O[k]){
                            delete userclick.o[m]
                        }
                       }
                    }
            })
            O.addEventListener('mouseover',()=>{
                O.style.paddingLeft='50px';
                O.style.border='2px solid rgb(152, 9, 126)';
            })
            O.addEventListener('mouseleave',()=>{
                O.style.padding='10px';
                O.style.border='1px solid yellow'
            })
           
            cardsec.appendChild(O)
            
            const br=document.createElement('br')
            cardsec.appendChild(br)

            const brr=document.createElement('br')
            cardsec.appendChild(brr)
            
            if(k===4){
                const btnn=document.createElement('button')
                btnn.textContent=`${sid} coin Medeb with ${arrayofcards[t]}`
                btnn.style.position='absolute'
                btnn.style.marginTop='-710px';
                btnn.style.padding='10px';
                btnn.style.border='none';
                btnn.style.borderRadius='10px';
                btnn.style.cursor='pointer'
                btnn.style.backgroundColor='rgb(7, 157, 159)'
                cardsec.appendChild(btnn)

                const btnnn=document.createElement('button')
                btnnn.setAttribute('class','bingowin')
                btnnn.textContent='BINGO WIN';
                btnnn.style.position='absolute'
                btnnn.style.marginTop='-890px';
                btnnn.style.cursor='pointer'
                btnnn.style.marginLeft='250px'
                btnnn.style.padding='10px';
                btnnn.style.border='none';
                btnnn.style.borderRadius='10px';
                btnnn.style.backgroundColor='rgb(7, 257, 259)'
                btnnn.style.color='red'
                btnnn.style.fontSize='15px'
                btnnn.addEventListener('click',()=>{
                    //alert(`${gcsn} and ${storedcartelanumber}`)
                    btnnn.style.backgroundColor='black';
                    btnnn.style.color='white'; 
                    console.log(gcsn)
                    console.log(storedcartelanumber)
                    console.log(userclick)
                    document.querySelector('.ntcalled').style.display='block'
                 ////These is the correct logic

/////////////////////###############THIS IS FOR TEST
//alert('trully congrate you winn')


////////////////////////////////////////////////////////////////////////

                              
                                 let a1=0,a2=0,a3=0,a4=0,a5=0;
                                 if(userclick.b.length>=5){
                                    a1++
                                 }
                                 if(userclick.i.length>=5){
                                      a2++
                                 }
                                 if(userclick.n.length>=5){
                                    a3++
                                 }
                                 if(userclick.g.length>=5){
                                    a4++
                                 }
                                 if(userclick.o.length>=5){
                                    a5++
                                 }
                            if((a1===1 && a2===1) || (a1===1 && a3===1) || 
                               (a1===1 && a4===1) || (a1===1 && a5===1) ||
                               (a2===1 && a3===1) || (a2===1 && a4===1) || 
                               (a2===1 && a5===1) ||
                               (a3===1 && a4===1) || (a3===1 && a5===1) || (a4===1 && a5===1)){
                               
                                 //let d=['b','i','n','g','o']
                                 let me1=0,me2=0,me3=0,me4=0,me5=0 
                                 let notcalled=[]
                              
                           for(let m=0;m<userclick.b.length;m++){
                                 if(gcsn.b.includes(userclick.b[m])){
                                 me1++ 
                                 }else{
                                    notcalled.push(userclick.b[m])
                                 }
                              }

                            for(let m=0;m<userclick.i.length;m++){
                                if(gcsn.i.includes(userclick.i[m])){
                                    me2++
                                }
                                else{
                                    notcalled.push(userclick.i[m])
                                }
                            }
                            
                            for(let m=0;m<userclick.n.length;m++){
                                if(gcsn.n.includes(userclick.n[m])){
                                    me3++
                                }
                                else{
                                    notcalled.push(userclick.n[m])
                                }
                            }

                            for(let m=0;m<userclick.g.length;m++){
                                if(gcsn.g.includes(userclick.g[m])){
                                    me4++
                                }
                                else{
                                    notcalled.push(userclick.g[m])
                                }
                            }

                            for(let m=0;m<userclick.o.length;m++){
                                if(gcsn.o.includes(userclick.o[m])){
                                    me5++
                                }
                                else{
                                    notcalled.push(userclick.o[m])
                                }
                            }


                          if((me1===5 && me2===5) || (me1===5 && me3===5) ||
                             (me1===5 && me4===5) || (me1===5 && me5===5) ||
                             (me2===5 && me3===5) || (me2===5 && me4===5) ||
                             (me2===5 && me5===5) || 
                             (me3===5 && me4===5) || (me3===5 && me5===5) ||
                             (me4===5 && me5===5)){
                                                                         
let dd=0
const dat={
name:sessionStorage.getItem('userName'),
phone:sessionStorage.getItem('phone'),
cardselected:storedcartelanumber,
clickednumber:userclick,
gamenum:gcsn,
medeb:id
}
socket.emit('winnersinfoo',dat)
//authAxois.post('/players/winners/info',dat).then(r=>r).catch(e=>e)
authAxois.post('/players/amount/length',dat).then((r)=>{
dd=r.data.length
//console.log(r.data.length)
document.querySelector('.notttcalled').style.display='block'
const dk=document.querySelector('.nottcalled')

const bx=document.createElement('button')
bx.textContent='X'
bx.style.backgroundColor='black'
bx.style.color='white'
bx.style.width='30px'
bx.style.height='30px'
bx.style.borderRadius='20px'
bx.style.marginLeft='250px'
bx.style.cursor='pointer'
bx.style.position='absolute'
bx.style.marginTop='-5px'
bx.style.border='none'
document.querySelector('.nottcalled').style.display='block';
bx.addEventListener('click',()=>{  
    document.querySelector('.nottcalled').style.display='none';
})
dk.appendChild(bx)

const igm=document.createElement('img')
igm.setAttribute('src',`${gamepicfour}`)
igm.style.width='50px'
igm.style.height='50px'
igm.style.padding='20px'
igm.style.position='absolute'
igm.style.borderRadius='30px'
dk.appendChild(igm)

dk.style.width='300px';
dk.style.height='100px';
dk.style.position='absolute'
dk.style.border='none'
dk.style.boxShadow='10px 10px 30px black'
dk.style.borderRadius='20px'
dk.style.marginTop='150px'
dk.style.marginLeft='50px'
dk.style.backgroundColor='#303030'
const kt=document.createElement('h2')
kt.textContent=`Congrate You Win ${dd*id} Birr`
kt.style.fontSize='15px'
kt.style.position='absolute'
kt.style.fontFamily='Arial, Helvetica, sans-serif';
kt.style.color='yellow'   
kt.style.fontSize='15px'
kt.style.marginLeft='80px'
kt.style.marginTop='20px'
dk.appendChild(kt)

const windate=document.createElement('p')
windate.textContent=`IN ${new Date().toLocaleDateString()} `
windate.style.fontSize='10px'
windate.style.position='absolute'
windate.style.fontFamily='Arial, Helvetica, sans-serif'
windate.style.color='white'
windate.style.marginLeft='100px'
windate.style.marginTop='40px'
dk.appendChild(windate)

const wintime=document.createElement('p')
wintime.textContent=` AT ${new Date().toLocaleTimeString()}`
wintime.style.marginLeft='170px'
wintime.style.marginTop='-20px'
wintime.style.fontSize='10px'
wintime.style.position='fixed'
wintime.style.fontFamily='Arial, Helvetica, sans-serif'
wintime.style.color='white'
wintime.style.marginTop='40px'
dk.appendChild(wintime)

for(let c=-150;c<50;c++){
   setTimeout(()=>{
    for(let b=0;b<c;b++){
        dk.style.marginLeft=`${b}px`
    }
   })
}

for(let k=10;k<550;k++){
    setTimeout(()=>{
     for(let d=10;d<k;d++){
         dk.style.marginTop=`${d}px`
     }
    })
 }

 for(let v=250;v>150;v--){
    setTimeout(()=>{
     for(let x=250;x>v;x--){
         dk.style.marginTop=`${x}px`
     }
    })
 }


const pl=document.querySelector('.winnaudio')
pl.play() 

}).catch((e)=>{
    console.log(e)
})
                                  
                              }
                              else{
                                //alert(`${notcalled}  is not being called \n total ${notcalled.length} number is not called`)
                                const nc=document.querySelector('.ntcalled')
                                
                                nc.style.width='350px';
                                nc.style.height='300px'
                                nc.style.backgroundColor='rgba(16, 15, 15, 0.729)'
                                nc.style.borderRadius='20px';
                                nc.style.position='absolute'
                                nc.style.marginTop='200px';
                                nc.style.border='1px solid yellow'
                                nc.style.overflow='scroll'
                                const ct=document.createElement('h2')
                                ct.style.fontSize='17px'
                                ct.style.fontFamily='arial'
                                ct.style.padding='10px'
                                ct.style.margin='10px'
                                ct.textContent='Sorry the following numbers \n are not being called';
                                ct.style.position='absolute'
                                ct.style.border='1px solid yellow'
                                ct.style.backgroundColor='rgba(27, 19, 21, 0.668)'
                                nc.appendChild(ct)
                             
                            for(let i=0;i<notcalled.length;i++){
                                if(notcalled[i]==='undefined'){
                                     console.log(`${notcalled[i]} is undefined`)
                                }else{
                                const num=document.createElement('p')
                                   num.textContent=`${notcalled[i]}`
                                   num.style.padding=`10px`
                                   num.style.marginLeft=`${i*70}px`
                                   num.style.marginTop='100px'
                                   num.style.position='absolute'
                                   num.style.fontFamily='arial'
                                  
                                   nc.appendChild(num)
                                }
                                   

                                       }
                                       const can=document.createElement('button')
                              can.textContent='cancel'
                              can.style.width='350px'
                              can.style.padding='10px'
                              can.style.position='absolute'
                              can.style.marginTop='250px'
                              can.style.marginLeft='20px'
                              can.style.cursor='pointer'
                              can.style.backgroundColor='blue'
                              
                              can.style.color='white'
                              can.style.border='none'
                              can.addEventListener('click',()=>{
                                document.querySelector('.ntcalled').style.display='none'
                               
                              })
                              document.querySelector('.ntcalled').appendChild(can)

                                     
                              }
                              
                                
                                  
                               }
                               else{
                                alert('sorry you did not winn')
                               }
                               
                            
       
///////////////////////////////////////////////////////////////////////////////                            


                                   

                             /////////else clossing logice//////
                             ////////
                                  
                                })
                                cardsec.appendChild(btnnn)
                            }

                        }

                      
                    ///////////////////these is the end of else logic
                        }

                    }).catch((e)=>{
                       console.log('problem during sending selected card due to ') 
                       console.log(e)    
                    })



                    
 /////////////////////////////////////////ending stop sending client to server                  
                }) 

                one.appendChild(btn)
               
               } 
               const br=document.createElement('br')
               one.appendChild(br)
            }

    }           
        

           }).catch((e)=>{
                 console.log('players game error has been ocrud')
           })
   

    // },[])
   const way1=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/amount/20` 
   const way2=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/amount/50` 
   const way3=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/amount/100` 
   const way4=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/amount/150` 
   const way5=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/amount/200` 
   const way6=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/amount/250`
   const way7=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/amount/300`
   const way8=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/amount/500`


    return(
        <div>

         <div className="gentle" style={{display:'none'}}>
            <img type='hidden' className="ig" src={gamepictwo} alt="" />
        </div>
        
        <div className="userListHeader">
<div className='userslist'>
   <ul> 
    <li><button onClick={()=>{
       if(clk===0){
        //document.querySelector('.playlist').style.marginLeft='500px';
        document.querySelector('.ssmobilenavbar').style.display='block';
        //document.querySelector('.shamberger div').style.backgroundColor='gray';
        //for(let n=-250;n<0;n++){
            setTimeout(()=>{
                for(let k=-250;k<0;k++){
                    document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                   // document.querySelector('.playlist').style.marginLeft=`${k}px`;
               }
            })
        //}
        clk++; 
    }
    else{

        //document.querySelector('.playlist').style.marginLeft='10px';
        //document.querySelector('.mobilenavbar').style.display='none';
        //document.querySelector('.shamberger div').style.backgroundColor='rgb(255, 0, 157)';
     //   for(let n=0;n>-250;n--){
            setTimeout(()=>{
                for(let k=0;k>-250;k--){
                    document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                   
                }
            })
     //   }
        clk=0; 
        document.querySelector('.ssmobilenavbar').style.display='none';
    }
    }}><img src={userName} alt=''/></button><p>{sessionStorage.getItem('userName')}<br/>
    {sessionStorage.getItem('phone')}</p></li>
    <li className='diposite'><button>Any 2 vertical close wins</button><p id='demo' style={{backgroundColor:'red',padding:'11px',marginTop:'-40px',borderRadius:'5px'}}></p></li>
   </ul>
</div>
<br/><br/>

<div className='amounts'>
<ul>
       <li onClick={()=>{document.location=`./${20}`}}><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={way1}>20Life Coin</Link></li> 
       <li onClick={()=>{document.location=`./${50}`}}><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={way2}>50Life Coin</Link></li> 
       <li onClick={()=>{document.location=`./${100}`}}><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={way3}>100Life Coin</Link></li> 
       <li onClick={()=>{document.location=`./${150}`}}><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={way4}>150Life Coin</Link></li> 
       <li onClick={()=>{document.location=`./${200}`}}><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={way5}>200Life Coin</Link></li> 
      
       <li onClick={()=>{document.location=`./${250}`}}><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={way6}>250Life Coin</Link></li> 
       <li onClick={()=>{document.location=`./${300}`}}><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={way7}>300Life Coin</Link></li>

       <li onClick={()=>{document.location=`./${500}`}}><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={way8}>500Life Coin</Link></li>   
     </ul>  
     </div>  
</div>

<div className='allcards'>
    <h1>Game Cards</h1>
    <div className='cardList'>
        <div className='selectecard'></div>
    </div>
</div>

<div className='playersArena'>
    <div className='gamesection'>
        <div className='gn'><h3>Game calling numbers area </h3></div>
        <div className='pb'><button>B</button></div>
        <div className='pi'><button>I</button></div>
        <div className='pn'><button>N</button></div>
        <div className='pg'><button>G</button></div>
        <div className='po'><button onClick={()=>{
            //const aud=document.querySelector('.audioEl')
            //aud.play()
        }}>O</button></div>
       <div style={{display:'none'}}>
          <audio className='audioEl' controls>
            <source src={gamenum}></source>
          </audio>
          <audio className='winnaudio' controls>
            <source src={win}></source>
          </audio>
       </div>
       <div className='directgamenumber'>
          <div className='b'></div>
          <div className='i'></div>
          <div className='n'></div>
          <div className='g'></div>
          <div className='o'></div>
       </div>

       <div className='rdnumber'>
       </div>
       <div className='gamestart' style={{display:'block'}}>THE GAME START IN</div>
       <div className='ready'></div>
    </div><br/>
    <div className='cardsection'>
        <div className='selcarnum'><h3>Your Selected Cartelas</h3></div>
        <div className='bin'></div><br/><br/><br/>
        <div className='cardsec'></div>
    </div>
</div>


<div className='playersInfo'>
    <div className='pllist'><h3 className='plllist'>Active Players list on these game</h3></div>
    <div className='playerCard'></div>
    <div className='channelplacename'></div>
    <div className='channelplace'>
        <p>players on these channel</p>
        <div className='channelplacename'></div>
    </div>
</div>
    <div></div>
    <br/><br/>
<div className='detail'>
    
    <div className='userdetail'>
        
        <div className='userdetaill'></div>
        <button onClick={()=>{
            document.querySelector('.detail').style.display='none';
        
        }}>cancel</button>
    </div>
</div>

<div className='ntcalled'>
    <div className='ntcc'></div>
</div>
<div className='notttcalled'>
    <div className='nottcalled'></div>
</div>

<div className='winnersbet'>
    <button className='wbtn' onClick={()=>{
        document.querySelector('.winnersbet').style.display='none'
    }}>X</button>
   <h1>Game Over<br/>WINNERS</h1>
</div>
<div className='maincho'>
<div className='cho'>
    <button onClick={()=>{
        //  setTimeout(()=>{
            window.location=`./${id}`
      //  },15000)
    }}>Re play</button>
    <button onClick={()=>{
        document.querySelector('.cho').style.display='none'
        document.querySelector('.maincho').style.display='none'
    }}>cancel</button><br/>
    <img src={gamepicfour} alt=""/>
</div>
</div>

<div className='footerrr'>
                   <div className='footerone'>
                   <button className="ddx" onClick={()=>{
                       
                       if(vall===0){ 
                         for(let i=600;i>450;i--){
                          setTimeout(()=>{
                            for(let k=600;k>=i;k--){
                              document.querySelector('.footerrr').style.marginTop=`${k}px`
                             // document.querySelector('.twothing').style.marginLeft=`${k}px`
                             // document.querySelector('.threething').style.marginLeft=`${k}px`
                            }

                          })
                         }
                        
                        document.querySelector('.ddx').innerHTML="V" 
                        vall++  
                      }else if(vall===1){
                        for(let i=450;i<=600;i++){
                          setTimeout(()=>{
                            for(let k=450;k<=i;k++){
                              document.querySelector('.footerrr').style.marginTop=`${k}px`
                             // document.querySelector('.twothing').style.marginLeft=`${k}px`
                              //document.querySelector('.threething').style.marginLeft=`${k}px`
                            }
                          })
                         
                         }


                         //document.querySelector('.footerr').style.marginTop="-250px"
                         document.querySelector('.ddx').innerHTML="^" 
                         vall=0
                      }
                     }}>^</button>  
                   </div>
                 <div className='footertw'>
                     <h3>We Are In</h3>
                   <img src={facebook} alt=""/>
                   <p>facebook</p> 
                   <img src={linkedin} alt=""/>
                   <p>LinkedIn</p>
                 </div>  

                 <div className='footertwo'>
                     <h3>Email</h3>
                   <img src={email} alt=""/>
                   <p>info@bingobet.net</p><br/>
                   <p>belay.unix@gmail.com</p>
                 </div>  
              
                 <div className='footerthree'>
                     <h3>phone</h3>
                   <img src={phoneNumber} alt=""/>
                   <p>+251900766703</p><br/>
                 
                 </div>  

                  <div className='footerfour'>
                  <h3>Location</h3>
                  <br/><br/>
                   <p>Addis Ababa/Ethiopia</p><br/>
                  </div>
                   
                <div className='powerd'>
                  <p>Developed By : Belay Basa</p>    
                </div>  
        </div>



<div className='mobilebottomnav'>
    <button className='pinfo' onClick={()=>{
          /*
          for(let i=10;i<750;i++){
            setTimeout(()=>{
                //for(let k=550;k>i;k--){
                    document.querySelector('.allcards').style.marginLeft=`${i}px`;
                    
                //}
            })
        }
        */
        document.querySelector('.pinfo').style.backgroundColor='rgb(16, 12, 12)'
        document.querySelector('.pinfo').style.color='yellow'

        document.querySelector('.gamebtn').style.backgroundColor='black'
        document.querySelector('.gamebtn').style.color='white'

        document.querySelector('.gamecard').style.backgroundColor='black'
        document.querySelector('.gamecard').style.color='white'

        for(let i=-250;i<5;i++){
            setTimeout(()=>{
                //for(let k=550;k>i;k--){
                    document.querySelector('.playersInfo').style.marginLeft=`${i}px`;
                //}
            })
        }

    }}><img src={register} alt=''/><p>Players</p></button>
    <button className='gamebtn' onClick={()=>{
        document.querySelector('.gamecard').style.backgroundColor='black'
        document.querySelector('.gamecard').style.color='white'

        document.querySelector('.pinfo').style.backgroundColor='black'
        document.querySelector('.pinfo').style.color='white'
       // document.querySelector('.playersInfo').style.display='none'
       for(let i=5;i>-455;i--){
        setTimeout(()=>{
            //for(let k=550;k>i;k--){
                document.querySelector('.playersInfo').style.marginLeft=`${i}px`;
            //}
        })
    }
       document.querySelector('.gamebtn').style.backgroundColor='rgb(16, 12, 12)'
        document.querySelector('.gamebtn').style.color='yellow'
        //document.querySelector('.allcards').style.display='one'
       // document.querySelector('.PlayersArena').style.display='block';
/*
       //now move the card number
          for(let i=10;i<750;i++){
            setTimeout(()=>{
                //for(let k=550;k>i;k--){
                    document.querySelector('.allcards').style.marginLeft=`${i}px`;
                    
                //}
            })
        }
       
 */
 
        for(let i=550;i>5;i--){
            setTimeout(()=>{
                //for(let k=550;k>i;k--){
                    document.querySelector('.playersArena').style.marginLeft=`${i}px`;
                //}
            })
        }

     
    }}><img src={gameim} alt=''/><p>Game</p></button>
    <button className='gamecard' onClick={()=>{
       document.querySelector('.allcards').style.display='block'
       for(let i=750;i>-10;i--){
        //document.querySelector('.allcards').style.backgroundColor='white'
        setTimeout(()=>{
            //for(let k=550;k>i;k--){
                document.querySelector('.allcards').style.marginLeft=`${i}px`;
                
            //}
        })
    }
              
       for(let i=5;i>-455;i--){
            setTimeout(()=>{
                //for(let k=550;k>i;k--){
                    document.querySelector('.playersInfo').style.marginLeft=`${i}px`;
                //}
            })
        }
       // document.querySelector('.playersArena').style.display='none'
       for(let i=5;i>-355;i--){
        setTimeout(()=>{
            //for(let k=550;k>i;k--){
                document.querySelector('.playersArena').style.marginLeft=`${i}px`;
            //}
        })
    }
    
        document.querySelector('.gamebtn').style.backgroundColor='black'
        document.querySelector('.gamebtn').style.color='white'


        document.querySelector('.gamecard').style.backgroundColor='rgb(16, 12, 12)'
        document.querySelector('.gamecard').style.color='yellow'
       
    }}><img src={bgame} alt=''/><p>Cartelas</p></button>
</div>



<div className='roundlevelchannel'>
    <div className='levelmessagechannel'>
        <button className='btnx' onClick={()=>{
            document.querySelector('.roundlevelchannel').style.display='none'
            navigate(`../user/${sessionStorage.getItem('userName')}/creatchannel`)
        }}>x</button>
       <h1>JOIN CHANNEL</h1>
         <div className='join'></div>
       <br/><br/>
      
    </div>
</div>



<div className='ssmobilenavbar'>
  
            <div className='ssmobilenavbarone'>
            <div className='cancle'>
               <p onClick={()=>{
                  
                              setTimeout(()=>{
                                  for(let k=0;k>=-250;k--){
                                      document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                                      //document.querySelector('.ssmobilenavbar').style.marginLeft=`${k}px`;
                                  }
                              })
                         // }
                          clk=0; 
                         setTimeout(()=>{
                          document.querySelector('.ssmobilenavbar').style.display='none';
                         },500) 
                          //document.querySelector('.ssmobilenavbar').style.display='none';
                      //}

               }}>x</p>
           </div>
           <br/><br/>
  <div className='profile'>
      <div className='profileOne'>
        <img src={userName} alt=""/>
         <div className='profileInfo'>
          <p style={{marginLeft:'30px',color:'white'}}>{sessionStorage.getItem('userName')}</p>
          <p><img src={phoneNumber} alt=''/> {sessionStorage.getItem('phone')}</p>
          <p style={{marginLeft:'100px',marginTop:'-15px'}}>$ {sessionStorage.getItem('currentBalance')}Birr </p>
         </div>
      </div>
    </div>            
    <ul>
                            <li  onClick={()=>{document.location=`../../${sessionStorage.getItem('userName')}/profile`}}>
                              Profile
                            </li>
                            <li onClick={()=>{document.location=`../../${sessionStorage.getItem('userName')}/playing-history`}}>
                            Playing History
                            </li>
                            <li onClick={()=>{document.location=`../../${sessionStorage.getItem('userName')}/winning-history`}}>
                             Winning History
                            </li>
                            <li onClick={()=>{document.location=`../../${sessionStorage.getItem('userName')}/transaction-history`}}>
                              Transaction History
                            </li>
                            <li onClick={()=>{document.location=`../../${sessionStorage.getItem('userName')}/message`}}>
                               Message
                            </li>
                            <li onClick={()=>{document.location=`../../${sessionStorage.getItem('userName')}/free-play`}}>
                              free play
                            </li>
                           
                        </ul>
                        <button style={{cursor:'pointer'}} onClick={()=>{
                          //sessionStorage.clear()
                           sessionStorage.clear('userName')
                           sessionStorage.clear('phone')
                           navigate('../auth/login')
                        }}>Logout</button>                   
 

                        </div>
                    </div>
        

        </div>
    )
}

export default Join;