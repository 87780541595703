import gamepicfour from '../images/gamepicfour.jpg';
import pwdd from '../images/pwdd.jpg';
import userName from '../images/userName.jpg';
import phoneNumber from '../images/phoneNumber.jpg';
import '../styles/signin.css';
import register from '../images/register.png';
import login from '../images/login.png';
import home from '../images/home.jpg';
import {Link,useNavigate} from 'react-router-dom';
//import axios from 'axios';
import authAxois from './requiestHandler';
import {createStore} from 'redux';
import facebook from '../images/facebook.jpg'
import linkedin from '../images/linkedin.jpg'
import email from '../images/email.jpg'
import reducer from '../reducers/mainReducer';


const store=createStore(reducer)
let vall=0;
function Singin(){

    /*
    const theButton=document.querySelector('.button')
    theButton.addEventListener('click',()=>{
        theButton.classList.add('button--loading')
    })
    */
  let clk=0;
  const navigate=useNavigate();
    return(<div className="routes" id="mainsingin">
       
        <div className="gentle">
            <img className="ig" src={gamepicfour} alt="" style={{width:"1200px",height:"700px"}}/>
        </div>
        <div className='shamberger' onClick={()=>{
            //let clk=0;
            if(clk===0){
                //document.querySelector('.playlist').style.marginLeft='500px';
                document.querySelector('.smobilenavbar').style.display='block';
                document.querySelector('.shamberger div').style.backgroundColor='gray';
                for(let n=-250;n<0;n++){
                    setTimeout(()=>{
                        for(let k=-250;k<=n;k++){
                            document.querySelector('.smobilenavbarone').style.marginLeft=`${k}px`;
                           // document.querySelector('.playlist').style.marginLeft=`${k}px`;
                        }
                    })
                }
                clk++; 
            }
            else{
        
                //document.querySelector('.playlist').style.marginLeft='10px';
                //document.querySelector('.mobilenavbar').style.display='none';
                document.querySelector('.shamberger div').style.backgroundColor='rgb(255, 0, 157)';
                for(let n=0;n>-250;n--){
                    setTimeout(()=>{
                        for(let k=0;k>=n;k--){
                            document.querySelector('.smobilenavbarone').style.marginLeft=`${k}px`;
                           
                        }
                    })
                }
                clk=0; 
                document.querySelector('.smobilenavbar').style.display='none';
            }
         }}>
           <div></div>
           <div></div>
           <div></div>
       </div>
             <div className='singinForm'>
           <div className='singin'>
             <h1>Create New Acount</h1>
             <div className='error'></div>
         <div className="input-field">
              <input className="pwd" id='userName' placeholder="Nic Name" required/>
                <div className="apn"></div>
           
         </div>
       <div className='addimg'><img src={userName} alt='' style={{borderRadius:"50px"}}/></div>  
       <div className="lgn">
       </div><br/><br/>
       <div className='named'></div>
       <div className="input-field">
              <input className="pwd" id='phone' placeholder='phone' required/>
                <div className="apn"></div>
               
         
             
         </div><br/><br/>
         <div className='phoned'></div>
        
         <div className='addimg' style={{marginTop:"-62px"}}><img src={phoneNumber} alt='' style={{borderRadius:"50px"}}/></div>

         <div className="input-field">
              <input className="pwd" type="password" id='password' placeholder="password" required/>
                <div className="apn"></div>
           
         </div>
       <div className='addimg'><img src={pwdd} alt='' style={{borderRadius:"50px"}} /></div>  
       <div className="lgn">
       </div><br/><br/>
       <div className='pwdd'></div>
       <div className="input-field">
              <input className="pwd" type="password" id='cpassword' placeholder="confirm password" required/>
                <div className="apn"></div>
          
         </div>
         <div className='addimg'><img src={pwdd} alt='' style={{borderRadius:"50px"}}/></div>

       <div className="lgn">
       </div>
      <br/> 
           <button className='button' style={{height:'50px'}} onClick={()=>{
            console.log(store.getState())
            const userInfo={
                name:document.querySelector('#userName').value,
                phone:document.querySelector('#phone').value,
                password:document.querySelector('#password').value,
                cpassword:document.querySelector('#cpassword').value,
            }
   if(userInfo.name==='' || userInfo.phone==='' || userInfo.password==='' || userInfo.cpassword===''){
      const er=document.querySelector('.error');
      const at=document.createElement('p');
      at.textContent='* All fields are required!!';
      at.style.backgroundColor='red';
      at.style.color='white';
      at.style.padding='10px';
      at.style.width='200px'
      at.style.position='absolute';
      at.style.marginTop='-120px';
      at.style.marginLeft='80px';
      er.appendChild(at)
   }         
else{   
 
if(userInfo.password===userInfo.cpassword){
    //const valid=/^[aZ-Az]/
    const valid=/^[a-z A-Z]+$/
    const validp=/^[0-9]/
    const pk=/^[a-z A-Z 0-9 @ # % - & ~ ! ]+$/
////////////////////

    if(valid.test(userInfo.name)){
         if(validp.test(userInfo.phone) && userInfo.phone.length===10){
            /////validation logic over her
          if(userInfo.password.length>=8){ 
             if(pk.test(userInfo.password)){
            document.querySelector('.button').classList.add('button--loading')
            document.querySelector('.singtxt').style.display='none';
////////////////////////////////////////////////////////////////////////// 
            //    localStorage.setItem('userName',userInfo.name);
            //    localStorage.setItem('phone',userInfo.phone);
            //    localStorage.setItem('currentBalance','0')


            //    sessionStorage.setItem('userName',userInfo.name);
           //     sessionStorage.setItem('phone',userInfo.phone);
          //      sessionStorage.setItem('currentBalance','0')
                //navigate(`/user/${userInfo.name}`)
                 

            
            authAxois.post('/user/register',userInfo).then((res)=>{
            //axios.post('https://rebuni.bingobet.net/user/register',userInfo).then((res)=>{
                console.log('good practice')
                console.log(res)
          
                sessionStorage.setItem('userName',res.data.name);
                sessionStorage.setItem('phone',res.data.phone);
                sessionStorage.setItem('currentBalance',res.data.currentBalance)
                navigate(`/user/${res.data.name}`)
                
              }).catch((e)=>{
               // alert(e)
                console.log(e)
                const er=document.querySelector('.error');
                const at=document.createElement('p');
                at.textContent='Network Error or System is not available:\n please cheak your Internate Connection';
                at.style.backgroundColor='red';
                at.style.color='white';
                at.style.padding='10px';
                at.style.position='absolute';
                at.style.marginTop='-120px';
                at.style.width='200px'
                at.style.marginLeft='80px';
                er.appendChild(at)
              
              })

                         }else{
                          const er=document.querySelector('.error');
                          const at=document.createElement('p');
                          at.textContent='* Wrong password \n please change your password!!';
                          at.style.backgroundColor='red';
                          at.style.color='white';
                          at.style.padding='10px';
                          at.style.width='200px'
                          at.style.position='absolute';
                          at.style.marginTop='-120px';
                          at.style.marginLeft='80px';
                          er.appendChild(at)
                         }
          
////////////////////////////////////////
/*****************          
sessionStorage.setItem('userName',userInfo.name);
sessionStorage.setItem('phone',userInfo.phone);
sessionStorage.setItem('currentBalance','0')
                //navigate(`/user/${res.data.name}`)
               
navigate(`/user/${userInfo.name}`)  
*************/   
            }else{
                alert("please make sure,your password\n must be more than 8 characters")
            }
         }else{
            //alert('phone number must be number')
            const pn=document.querySelector('.phoned')
            const pk=document.createElement('p')
            pk.textContent=`*Phone Number is not valid`
            pk.style.color='red'
            pk.style.fontFamily='arial'
            pk.style.marginTop='-30px'
            pk.style.marginLeft='70px'
            pk.style.position='absolute'
            pn.appendChild(pk)
            
         }
    }else{
        alert("only characters is allowed in user\n name field")
    }    
   
 }else{
    //alert(userInfo.password.length)
   const er=document.querySelector('.error');
   const at=document.createElement('p');
   at.textContent='*confirm passwrod is not matched!!';
   at.style.backgroundColor='red';
   at.style.color='white';
   at.style.padding='10px';
   at.style.width='200px'
   at.style.position='absolute';
   at.style.marginTop='-120px';
   at.style.marginLeft='80px';
   er.appendChild(at)
 }

}    
    }}><span className='button__text'><p className='singtxt' style={{marginTop:'-10px'}}>signUp</p></span></button><br/><br/>
           <p><Link to="/auth/login" style={{color:"white",marginLeft:"100px",padding:"20px"}}>Alread has Acount?</Link></p>  
           </div>
        </div>


        <div className='footerr'>
                   <div className='footerone'>
                   <button className="ddx" onClick={()=>{
                       
                       if(vall===0){ 
                         for(let i=300;i<450;i++){
                          setTimeout(()=>{
                            for(let k=300;k<=i;k++){
                              document.querySelector('.footerr').style.marginTop=`${k}px`
                             // document.querySelector('.twothing').style.marginLeft=`${k}px`
                             // document.querySelector('.threething').style.marginLeft=`${k}px`
                            }

                          })
                         }
                        
                        document.querySelector('.ddx').innerHTML="^" 
                        document.querySelector('.footerr').style.height='500px'
                        vall++  
                      }else if(vall===1){
                        for(let i=450;i>=330;i--){
                          setTimeout(()=>{
                            for(let k=450;k>=i;k--){
                              document.querySelector('.footerr').style.marginTop=`${k}px`
                             // document.querySelector('.twothing').style.marginLeft=`${k}px`
                              //document.querySelector('.threething').style.marginLeft=`${k}px`
                            }
                          })
                         
                         }


                         //document.querySelector('.footerr').style.marginTop="-250px"
                         document.querySelector('.ddx').innerHTML="V" 
                         vall=0
                      }
                     }}>V</button>  
                   </div>
                 <div className='footertw'>
                     <h3>We Are In</h3>
                   <img src={facebook} alt=""/>
                   <p>facebook</p> 
                   <img src={linkedin} alt=""/>
                   <p>LinkedIn</p>
                 </div>  

                 <div className='footerrtwo'>
                     <h3>Email</h3>
                   <img src={email} alt=""/>
                   <p>info@bingobet.net</p><br/>
                   <p>belay.unix@gmail.com</p>
                 </div>  
              
                 <div className='footerrthree'>
                     <h3>phone</h3>
                   <img src={phoneNumber} alt=""/>
                   <p>+251900766703</p><br/>
                 
                 </div>  

                  <div className='footerrfour'>
                  <h3>Location</h3>
                  <br/><br/>
                   <p>Addis Ababa/Ethiopia</p><br/>
                  </div>
                   
                  
        </div>
            

        
        <div className='smobilenavbar'>
            
    <div className='smobilenavbarone'>
            <ul>
                    <li>
                       <img src={home} alt=''/> <Link to="/" style={{textDecoration:"none",color:"white"}}><button>Home</button></Link>
                    </li>
                 
                    <li>
                    <img src={register} alt=''/><Link to="/auth/Singin" style={{textDecoration:"none",color:"white"}}><button style={{backgroundColor:'blue',color:'white'}}>Register</button></Link>
                    </li>
                    <li>
                       <img src={login} alt=''/> <Link to="/auth/Login" style={{textDecoration:"none",color:'white'}}><button style={{backgroundColor:'blue',color:'white'}}>Login</button></Link>
                    </li>
                    
                </ul>
                </div>
            </div>
<p id="demo" style={{display:'none'}}></p>
        </div>)
}
export default Singin;