//front react and nodejs by using javascript programming language
import userName from '../images/userName.jpg';
import phoneNumber from '../images/phoneNumber.jpg';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../styles/users.css';
//import userName from '../images/userName.jpg';
import authAxois from '../pages/requiestHandler';
import { useEffect } from 'react';
import register from '../images/register.png'
import cnm from '../images/cnm.jpg'
import gameim from '../images/gameim.jpg'
//import gamepictwo from '../images/gamepictwo.jpg';
//import { useNavigate } from 'react-router-dom';
//import axios from 'axios'


const Users=()=>{
const {id}=useParams()
let clk=0;
useEffect(()=>{
  if(id===50){
    alert('hello id number')
  }
})
//const bk=document.getElementByTagName('li')

//var myVar = setInterval(myTimer, 1000);
//setInterval(myTimer, 1000);
function tt() {
    var d = new Date().toLocaleTimeString();
    const dk= document.querySelector('#demo')
    dk.style.backgroundColor='red'
    dk.style.width='100px'
    dk.style.position='absolute'
    dk.style.marginLeft='810px'
    dk.style.padding='13px'
    dk.style.marginTop='25px'
    dk.style.borderRadius='10px'
    dk.innerHTML = d;
}
setInterval(tt, 1000)
const navigate=useNavigate();

const players=[]

useEffect(()=>{
   let a=[];
   let b=0;
   let b1=0;
   //let b2=0,b3=0,b4=0,b5=0,b6=0,b7=0,b8=0,b9=0,b10=0
   //setInterval(()=>{
   

  //axios.post('https://game2free.bingobet.net/players/amount/length').then((res)=>{
  authAxois.post('/api/players/amount/length').then((res)=>{  
  console.log(res.data)

       document.querySelector('.onetime').innerHTML=res.data[0].playingtime
        for(let k=0;k<res.data.length;k++){
          let t=Math.floor(Math.random()*9)+1
          let m=Math.floor(Math.random()*9)+1
          let n=Math.floor(Math.random()*9)+1
          const mane=document.createElement('p')
          mane.textContent=res.data[k].playername.slice(0,2)
          mane.style.backgroundColor=`#15${t}1${m}${n}`
          mane.style.padding='10px'
          mane.style.width='20px'
          mane.style.height='20px'
          mane.style.fontSize='15px'
          mane.style.borderRadius='30px'
          document.querySelector('.playerattempttoplay').appendChild(mane)

          const hu=document.createElement('h3')
          hu.textContent=res.data[k].playername
          hu.style.position='absolute'
          hu.style.marginLeft='70px'
          hu.style.fontSize='15px'
          hu.style.marginTop='-140px'
          document.querySelector('.playerattempttoplay').appendChild(hu)

          const h=document.createElement('p')
          h.textContent=res.data[k].playerphone
          h.style.position='absolute'
          h.style.color='gray'
          h.style.marginLeft='60px'
          h.style.fontSize='15px'
          h.style.marginTop='-125px'
          document.querySelector('.playerattempttoplay').appendChild(h)

          const tk=document.createElement('br')
          document.querySelector('.playerattempttoplay').appendChild(tk)

            if(res.data[k].medeb===20){
              b++
              b1++;
              //b2++;b3++;b4++;b5++;b6++;b7++;b8++;b9++;b10++
            }  
        }
        document.querySelector('.oneamount').innerHTML=b
        document.querySelector('.onewinning').innerHTML=`${b*20} Birr`

        document.querySelector('.onetime1').innerHTML=res.data[1].playingtime 
        document.querySelector('.oneamount1').innerHTML=b1
        document.querySelector('.onewinning1').innerHTML=`${b1*20} Birr`
      }).catch((e)=>{
        console.log(e)
      })
      a=[...new Set(players)] 
   //},5000)
console.log(a)
})

//const winner=()=>{}
/////over here append all winners
//axios.post('https://game2free.bingobet.net/players/allwinners').then((res)=>{
authAxois.post('/api/players/allwinners').then((res)=>{  
console.log('winners response is comming...')
  console.log(res.data)
  const win=document.querySelector('.allwinnerss')
  win.style.backgroundColor='black'
for(let n=0;n<res.data.length;n++){ 
let t=Math.floor(Math.random()*9)+1  
let t1=Math.floor(Math.random()*9)+1  
let t2=Math.floor(Math.random()*9)+1 
//let t3=Math.floor(Math.random()*9)+1   
  const userwin=document.createElement('button')
  userwin.textContent=res.data[n].winnersname.slice(0,1)
  userwin.style.width='40px';
  userwin.style.height='40px';
  userwin.style.backgroundColor=`#${t}5${t1}1${t2}5`
  userwin.style.borderRadius='50px';
  userwin.style.fontSize='20px';
  userwin.style.paddding='10px';
  userwin.style.margin='20px'
  userwin.style.color='white'
  userwin.style.border='none'
  userwin.style.justifyContent='center'
  win.appendChild(userwin)

  const name=document.createElement('p')
  name.textContent=res.data[n].winnersname
  name.style.marginLeft='80px';
  name.style.fontFamily='arial'
  name.style.marginTop='-55px'
  win.appendChild(name)

  const dt=document.createElement('p')
  dt.textContent=`on ${res.data[n].winnersdate} at ${res.data[n].winnerstime} in ${res.data[n].medeb}ETB   `
  dt.style.color='gray'
  dt.style.fontSize='10px'
  dt.style.marginLeft='80px'
  dt.style.marginTop='-15px'
  win.appendChild(dt)

  const pr=document.createElement('p')
  pr.textContent=`${res.data[n].winnersprice}ETB Winner`
  pr.style.backgroundColor='black';
  pr.style.border='1px solid yellow'
  pr.style.padding='10px';
  pr.style.width='150px';
  pr.style.borderRadius='10px'
  pr.style.paddingLeft='50px'
  pr.style.fontFamily='arial'
  win.appendChild(pr)
  const brr=document.createElement('br')
  win.appendChild(brr)


}
}).catch(e=>e)

const one=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/20`
const two=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/50`
//const two=`http://localhost:3000/user/${sessionStorage.getItem('userName')}/50`
const three=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/100`
const four=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/150`
const five=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/200`
const six=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/250`
const seven=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/300`
const eight=`https://bingobet.net/user/${sessionStorage.getItem('userName')}/500`

return(
<div className="userListHeader">
<div className='userslist'>
   <ul> 
    <li><button onClick={()=>{
       if(clk===0){
        //document.querySelector('.playlist').style.marginLeft='500px';
        document.querySelector('.ssmobilenavbar').style.display='block';
        //document.querySelector('.shamberger div').style.backgroundColor='gray';
        for(let n=-250;n<0;n++){
            setTimeout(()=>{
                for(let k=-250;k<=n;k++){
                    document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                   // document.querySelector('.playlist').style.marginLeft=`${k}px`;
                }
            })
        }
        clk++; 
    }
    else{

        //document.querySelector('.playlist').style.marginLeft='10px';
        //document.querySelector('.mobilenavbar').style.display='none';
        //document.querySelector('.shamberger div').style.backgroundColor='rgb(255, 0, 157)';
        for(let n=0;n>-250;n--){
            setTimeout(()=>{
                for(let k=0;k>n;k--){
                    document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                   
                }
            })
        }
        clk=0; 
        document.querySelector('.ssmobilenavbar').style.display='none';
    }
    }}>
      <div></div>
      <div></div>
      
      <img src={userName} alt=''/></button>
      <div className='unm'><p>{sessionStorage.getItem('userName')}<br/>
    {sessionStorage.getItem('phone')}</p>
      </div>
    </li>
    
   </ul>
</div>
<br/><br/>

<div className='amounts'>
     <ul>
       <li><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={one}>20Coins Round 1</Link></li> 
       <li><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={two}>50Coins Round 2</Link></li> 
       <li><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={three}>100coins Round 3</Link></li> 
       <li><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={four}>150coins Round 4</Link></li> 
       <li><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={five}>200coins Round 5</Link></li> 
      
       <li><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={six}>250coins Round 6</Link></li> 
       <li><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={seven}>300coins Round 7</Link></li>

       <li><Link style={{textDecoration:'none',color:'black',fontFamily:'arial'}} to={eight}>500coins Round 8</Link></li>   
     </ul>  
  </div>  

<div className='playsection'>


<table>
   <div className='medeb'>
    <h2 style={{paddingLeft:'200px',fontFamily:'arial'}}>{id} Coins Life</h2>
   </div>
 <br/><br/>
  <tr>
    <td><h3>COINS</h3></td>
    <td><h3>Players</h3></td>
    <td><h3>Winning price</h3></td>
    <td><h3>Winn Now</h3></td>
  </tr>
  <tr>
    <td><p className='onetime'>20 coins</p></td>
    <td><p className='oneamount'>1 - 500</p></td>
    <td><p className='onewinning'>up to 500 coins</p></td>
    <td><button className='buttton' onClick={()=>{
       document.querySelector('.buttton').classList.add('buttton--loading')
       document.querySelector('.singtxt').style.display='none';

       const playerInfo={
        playerName:sessionStorage.getItem('userName'),
        playerPhone:sessionStorage.getItem('phone')
       };
       //navigate(`../user/${sessionStorage.getItem('userName')}/amount/20`)
       authAxois.post('/user/player/game/10',playerInfo).then((res)=>{
       //axios.get('https://game.bingobet.net/user/player/game/10').then((res)=>{  
       console.log('player game response is comming.....') 
         console.log(res.data)
         
         navigate(`../user/${sessionStorage.getItem('userName')}/amount/20`)
       }).catch((e)=>{
             console.log('players game error has been ocrud')
       })

}}><span className='buttton__text'><p className='singtxt' style={{marginTop:'-8px'}}>play</p></span></button></td>
  </tr>
</table>

<table>
  <tr>
  <td><h3>SPECTIAL ROUND</h3></td>
    <td><h3>Players</h3></td>
    <td><h3>Winning price</h3></td>
    <td><h3>Winn Now</h3></td>
  </tr>
  <tr>
    <td><p className='onetime1'>1 GOLD COIN</p></td>
    <td><p className='oneamount1'>10 up to 10,000</p></td>
    <td><p className='onewinning1'>Mobile phone OR <br/>Smart Watch OR ETB</p></td>
    <td><button className='buttton' onClick={()=>{
       document.querySelector('.buttton').classList.add('buttton--loading')
       document.querySelector('.singtxt').style.display='none';
       const playerInfo={
        playerName:sessionStorage.getItem('userName'),
        playerPhone:sessionStorage.getItem('phone')
       };
       //navigate(`../user/${sessionStorage.getItem('userName')}/amount/50`)
       authAxois.post('/user/player/game/10',playerInfo).then((res)=>{
      //axios.get('https://game.bingobet.net/user/player/game/10').then((res)=>{   
      console.log('player game response is comming.....') 
         console.log(res.data)
          //navigate(`../user/${sessionStorage.getItem('userName')}/amount/50`)
          navigate(`../user/${sessionStorage.getItem('userName')}/amount/Special-Round/50`)
        }).catch((e)=>{
             console.log('players game error has been ocrud')
       })
    }}><p style={{color:'white',textDecoration:'none'}}>play</p></button></td>
  </tr>
</table>

<table>
  <tr>
  <td><h3>COINS</h3></td>
    <td><h3>Players</h3></td>
    <td><h3>Winning price</h3></td>
    <td><h3>Winn Now</h3></td>
  </tr>
  <tr>
    <td><p>100 COINS</p></td>
    <td><p>10 - 100</p></td>
    <td><p>up to 100 coins</p></td>
    <td><button className='buttton' onClick={()=>{
       document.querySelector('.buttton').classList.add('buttton--loading')
       document.querySelector('.singtxt').style.display='none';
       
       const playerInfo={
        playerName:sessionStorage.getItem('userName'),
        playerPhone:sessionStorage.getItem('phone')
       };
       //navigate(`../user/${sessionStorage.getItem('userName')}/amount/100`)
       authAxois.post('/user/player/game/10',playerInfo).then((res)=>{
       // axios.get('https://game.bingobet.net/user/player/game/10').then((res)=>{   
       console.log('player game response is comming.....') 
         console.log(res.data)
          navigate(`../user/${sessionStorage.getItem('userName')}/amount/100`)
       }).catch((e)=>{
             console.log('players game error has been ocrud')
       }) 

    }}><p style={{color:'white',textDecoration:'none'}}>play</p></button></td>
  </tr>
</table>

<table>
  <tr>
  <td><h3>SPECIAL ROUND</h3></td>
    <td><h3>Players</h3></td>
    <td><h3>Winning price</h3></td>
    <td><h3>Winn Now</h3></td>
  </tr>
  <tr>
    <td><p>2 GOLD COINS</p></td>
    <td><p>10 up to 100,000</p></td>
    <td><p>$ Suprizes<br/></p></td>
    <td><button className='buttton' onClick={()=>{
       document.querySelector('.buttton').classList.add('buttton--loading')
       document.querySelector('.singtxt').style.display='none';

       const playerInfo={
        playerName:sessionStorage.getItem('userName'),
        playerPhone:sessionStorage.getItem('phone')
       };
       //navigate(`../user/${sessionStorage.getItem('userName')}/amount/150`)
      
       authAxois.post('/user/player/game/10',playerInfo).then((res)=>{
         console.log('player game response is comming.....') 
         console.log(res.data)
         //console.log(res.data)
         navigate(`../user/${sessionStorage.getItem('userName')}/amount/Special-Round/75`)
       }).catch((e)=>{
             console.log('players game error has been ocrud')
       })
    }}><p style={{color:'white',textDecoration:'none'}}>play</p></button></td>
  </tr>
</table>




<table>
  <tr>
  <td><h3>COINS</h3></td>
    <td><h3>Players</h3></td>
    <td><h3>Winning price</h3></td>
    <td><h3>Winn Now</h3></td>
  </tr>
  <tr>
    <td><p>200 coins</p></td>
    <td><p>1-1,000</p></td>
    <td><p>up to 200 coins</p></td>
    <td><button className='buttton' onClick={()=>{
       document.querySelector('.buttton').classList.add('buttton--loading')
       document.querySelector('.singtxt').style.display='none';

       const playerInfo={
        playerName:sessionStorage.getItem('userName'),
        playerPhone:sessionStorage.getItem('phone')
       };
       navigate(`../user/${sessionStorage.getItem('userName')}/amount/200`)
       authAxois.post('/user/player/game/10',playerInfo).then((res)=>{
       // axios.get('https://game.bingobet.net/user/player/game/10').then((res)=>{   
       console.log('player game response is comming.....') 
         console.log(res.data)
         //console.log(res.data)
         navigate(`../user/${sessionStorage.getItem('userName')}/amount/200`)
       }).catch((e)=>{
             console.log('players game error has been ocrud')
       })
    }}><p style={{color:'white',textDecoration:'none'}}>play</p></button></td>
  </tr>
</table>


<table>
  <tr>
  <td><h3>COINS</h3></td>
    <td><h3>Players</h3></td>
    <td><h3>Winning price</h3></td>
    <td><h3>Winn Now</h3></td>
  </tr>
  <tr>
    <td><p>250 coins</p></td>
    <td><p>1-10,000</p></td>
    <td><p>up to 250 coins</p></td>
    <td><button className='buttton' onClick={()=>{
       document.querySelector('.buttton').classList.add('buttton--loading')
       document.querySelector('.singtxt').style.display='none';

       const playerInfo={
        playerName:sessionStorage.getItem('userName'),
        playerPhone:sessionStorage.getItem('phone')
       };
       navigate(`../user/${sessionStorage.getItem('userName')}/amount/250`)
      authAxois.get('/user/player/game/10',playerInfo).then((res)=>{
       // axios.get('/user/card').then((res)=>{
        console.log('player game response is comming......') 
         console.log(res.data)
         //console.log(res.data)
         navigate(`../user/${sessionStorage.getItem('userName')}/amount/250`)
       }).catch((e)=>{
             console.log('players game error has been ocrud')
       })
    }}><p style={{color:'white',textDecoration:'none'}}>play</p></button></td>
  </tr>
</table>


<table>
  <tr>
  <td><h3>COINS</h3></td>
    <td><h3>Players</h3></td>
    <td><h3>Winning price</h3></td>
    <td><h3>Winn Now</h3></td>
  </tr>
  <tr>
    <td><p>300 coins</p></td>
    <td><p>10-20,000</p></td>
    <td><p>300 coins</p></td>
    <td><button className='buttton' onClick={()=>{
       document.querySelector('.buttton').classList.add('buttton--loading')
       document.querySelector('.singtxt').style.display='none';

       const playerInfo={
        playerName:sessionStorage.getItem('userName'),
        playerPhone:sessionStorage.getItem('phone')
       };
      
       navigate(`../user/${sessionStorage.getItem('userName')}/amount/300`)
       authAxois.post('/user/player/game/10',playerInfo).then((res)=>{
      //  authAxois.get('/user/card').then((res)=>{
         console.log('player game response is comming.....') 
         console.log(res.data)
         //console.log(res.data)
         navigate(`../user/${sessionStorage.getItem('userName')}/amount/300`)
       }).catch((e)=>{
             console.log('players game error has been ocrud')
       })
    }}><p style={{color:'white',textDecoration:'none'}}>play</p></button></td>
  </tr>
</table>

<table>
  <tr>
  <td><h3>SPECIAL ROUND</h3></td>
    <td><h3>No of Players</h3></td>
    <td><h3>Winning price</h3></td>
    <td><h3>Winn Now</h3></td>
  </tr>
  <tr>
    <td><p>1 GOLD COIN</p></td>
    <td><p>more than 10</p></td>
    <td><p>smart phone,watch or money</p></td>
    <td><button className='buttton' onClick={()=>{
       document.querySelector('.buttton').classList.add('buttton--loading')
       document.querySelector('.singtxt').style.display='none';

       const playerInfo={
        playerName:sessionStorage.getItem('userName'),
        playerPhone:sessionStorage.getItem('phone')
       };
       navigate(`../user/${sessionStorage.getItem('userName')}/amount/Special-Round/50`) 
       authAxois.post('/user/player/game/10',playerInfo).then((res)=>{
       // axios.get('https://game2free.bingobet.net/user/card').then((res)=>{
         console.log('player game response is comming.....') 
         console.log(res.data)
         //console.log(res.data)
         navigate(`../user/${sessionStorage.getItem('userName')}/amount/500`)
       }).catch((e)=>{
             console.log('players game error has been ocrud')
       })
    }}><p style={{color:'white',textDecoration:'none'}}>play</p></button></td>
  </tr>
</table>

</div>

<div className='playerattempttoplayy'>
  <h2>players on {id} Life Coin</h2>
  <div className='playerattempttoplay'></div>
</div>

<div className='allwinners'>
  <h2>Top Life Coin Winners</h2>
  <div className='allwinnerss'></div>
</div>




<div className='mobilebottomnavv'>
    <button className='pinfo' onClick={()=>{
    
        document.querySelector('.pinfo').style.backgroundColor='rgb(16, 12, 12)'
        document.querySelector('.pinfo').style.color='yellow'

        document.querySelector('.gamebtn').style.backgroundColor='black'
        document.querySelector('.gamebtn').style.color='white'

        document.querySelector('.gamecard').style.backgroundColor='black'
        document.querySelector('.gamecard').style.color='white'

       document.querySelector('.allwinners').style.display='block'
       document.querySelector('.playerattempttoplayy').style.display='none'
       document.querySelector('.playsection').style.display='none'


    }}><img src={cnm} alt=''/><p>Winners</p></button>
    <button className='gamebtn' onClick={()=>{
        document.querySelector('.gamecard').style.backgroundColor='black'
        document.querySelector('.gamecard').style.color='white'

        document.querySelector('.pinfo').style.backgroundColor='black'
        document.querySelector('.pinfo').style.color='white'
       // document.querySelector('.playersInfo').style.display='none'
    
       document.querySelector('.gamebtn').style.backgroundColor='rgb(16, 12, 12)'
        document.querySelector('.gamebtn').style.color='yellow'

       ///
       document.querySelector('.allwinners').style.display='none'
       document.querySelector('.playsection').style.display='block'
       document.querySelector('.playerattempttoplayy').style.display='none'
    }}><img src={gameim} alt=''/><p>Game</p></button>
    <button className='gamecard' onClick={()=>{
       
   
    
        document.querySelector('.gamebtn').style.backgroundColor='black'
        document.querySelector('.gamebtn').style.color='white'


        document.querySelector('.gamecard').style.backgroundColor='rgb(16, 12, 12)'
        document.querySelector('.gamecard').style.color='yellow'

        document.querySelector('.allwinners').style.display='none'
        document.querySelector('.playsection').style.display='none'
        document.querySelector('.playerattempttoplayy').style.display='block'
       
    }}><img src={register} alt=''/><p>Players</p></button>
</div>




<div className='ssmobilenavbar'>
  
            <div className='ssmobilenavbarone'>
            <div className='cancle'>
               <p onClick={()=>{
                      /*
                      if(clk===0){
                          //document.querySelector('.playlist').style.marginLeft='500px';
                          document.querySelector('.ssmobilenavbar').style.display='block';
                          //document.querySelector('.shamberger div').style.backgroundColor='gray';
                          for(let n=-250;n<0;n++){
                              setTimeout(()=>{
                                  for(let k=-250;k<=n;k++){
                                      document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                                     // document.querySelector('.playlist').style.marginLeft=`${k}px`;
                                  }
                              })
                          }
                          clk++; 
                      }
                     */ 
                      //else{
                  
                          //document.querySelector('.playlist').style.marginLeft='10px';
                          //document.querySelector('.mobilenavbar').style.display='none';
                          //document.querySelector('.shamberger div').style.backgroundColor='rgb(255, 0, 157)';
                          for(let n=0;n>-250;n--){
                              setTimeout(()=>{
                                  for(let k=0;k>=n;k--){
                                      document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                                      //document.querySelector('.ssmobilenavbar').style.marginLeft=`${k}px`;
                                  }
                              })
                          }
                          clk=0; 
                         setTimeout(()=>{
                          document.querySelector('.ssmobilenavbar').style.display='none';
                         },500) 
                          //document.querySelector('.ssmobilenavbar').style.display='none';
                      //}




               }}>x</p>
           </div>
           <br/><br/>
    <div className='profile'>
      <div className='profileOne'>
        <img src={userName} alt=""/>
         <div className='profileInfo'>
          <p style={{marginLeft:'30px',color:'white'}}>{sessionStorage.getItem('userName')}</p>
          <p><img src={phoneNumber} alt=''/> {sessionStorage.getItem('phone')}</p>
          <p style={{marginLeft:'100px',marginTop:'-15px'}}>$ {0} Birr </p>
          <p style={{marginLeft:'10px',marginTop:'-10px'}}>Total Winning Coin : {localStorage.getItem('coin')} coin</p>
         </div>
      </div>
    </div>       
                    <ul>
                      <li onClick={()=>{
                        navigate(`./creatchannel/${20}`)
                      }}>Create Group</li>
                      <li onClick={()=>{
                        navigate(`./joinchannel/${20}`)
                      }}>join Group</li>
                            <li onClick={()=>{
                            navigate('./profile')
                            }}>
                              Profile
                            </li>
                            <li onClick={()=>{
                              navigate('./playing-history')
                            }}>
                            Playing History
                            </li>
                            <li onClick={()=>{
                               navigate('./winning-history')
                            }}>
                             Winning History
                            </li>
                            <li onClick={()=>{
                              navigate('./transaction-history')
                            }}>
                              Transaction History
                            </li>
                      
                            <li onClick={()=>{
                              navigate('./message')
                            }}>
                              Messages
                            </li>
                            <li onClick={()=>{
                              navigate('./free-play')
                            }}>
                               Free Play
                            </li>
                           
                        </ul>
                        <button onClick={()=>{
                          //sessionStorage.clear()
                        
                        }}>Logout</button>

                        </div>
                    </div>
        


</div>
)
}

export default Users;
