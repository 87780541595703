import {Route, Routes} from 'react-router-dom';
import Head from './components/Header';
//import Head from './components/Header';
import Home from './pages/Home'
import Acount from './pages/Acount'
import Play from './pages/Play'
import Login from './pages/Login'
import Singin from './pages/Singin'
import NotFound from './pages/NotFound';
import User from './pages/User';
import Userrr from './pages/Userrr';
import Game from './pages/Thegame';
import Myprofile from './pages/profile';
import PlayingHistory from './pages/phistory';
import WinningHistory from './pages/whistory';
import TransactionHistory from './pages/thistory';
import Message from './pages/message';
import FreePlay from './pages/freeplay';
import Nimda from './pages/jhonecriper'
import AllWinners from './pages/authWinners';
import Channel from './pages/Thechannel';
import Join from './pages/Thejoin'
import Special from './pages/Special';
import Final from './pages/Final'
import PrivateGame from './pages/privateBingo';
function App(){
    
    return(
      <>   
      <div>
        <Head />
      </div>
      <Routes>  
          <Route path="/" element={<Home/>} exact/>
          <Route path="/Acount" element={<Acount/>} />
          <Route path="/Play" element={<Play/>} />
          <Route path="/auth/SingIn" element={<Singin/>} />
          <Route path="/auth/Login" element={<Login/>} />
          <Route path="/user/:id" element={<User/>}/>
          <Route path="/user/:id/:id" element={<Userrr/>}/>
          <Route path="/user/:id/amount/:id" element={<Game/>}/>
          <Route path="/user/:id/amount/Special-Round/:id" element={<Special/>}/>
          <Route path="/user/:id/amount/Final-Round/:id" element={<Final/>}/>
          <Route path="/user/:id/creatchannel/:id" element={<Channel/>}/>
          <Route path="/user/:id/joinchannel/:id" element={<Join/>}/>
          <Route path="/user/:id/profile" element={<Myprofile/>}/>
          <Route path="/user/:id/playing-history" element={<PlayingHistory/>}/>
          <Route path="/user/:id/winning-history" element={<WinningHistory/>}/>
          <Route path="/user/:id/transaction-history" element={<TransactionHistory/>}/>
          <Route path="/user/:id/message" element={<Message/>}/>
          <Route path="/user/:id/free-play" element={<FreePlay/>}/>
          <Route path="/nimdaUser/auth/nimda/allplayers" element={<Nimda/>}/>
          <Route path="/nimdaUser/auth/nimda/allwinners" element={<AllWinners/>}/>
          <Route path="/customer/private-bingo" element={<PrivateGame />} />
          <Route element={<NotFound />}/>
        
      </Routes> 
     
        
       
   
     

 
  </>     
    )

    
}

export default App;