
//import { bindActionCreators } from "redux";


export default function reducer(state=[{name:'',phone:'',currentBalance:'',accessToken:''}],action){
    switch(action.type){
        case 'registerd':
             return {
               username:action.name,
               phone:action.phone,
               accessToken:action.accessToken,
               currentBalance:action.currentBalance 
             }
         default:
             return state;    
    }
}
