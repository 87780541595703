import '../styles/allroutes.css';
import gamepictwo from '../images/gamepictwo.jpg';
//clearInterval();
//clearInterval();
const Play=()=>{
    return(<div className="routes" style={{backgroundColor:'blue'}}>
           <h1>Play the game</h1>
           <div className="gentle">
            <img className="ig" src={gamepictwo} alt="" style={{width:"1200px",height:"700px"}}/>
        </div>
        </div>)
}
export default Play;