import avat  from '../images/avat.jpg';
import avat2 from '../images/avat2.jpg';
import "../styles/private.css";
function PrivateGame(){
    //document.querySelectorAll('.demo').remove()
    return(<div className="privatenav">
           <div className="nav-links">
              <div className="liks">
                <ul>
                  <li><img src={avat} alt=""/><br/>
                      <h2>Adminstrator</h2>
                   </li>
                   <li><img src={avat2} alt=""/><br/>
                      <h2>Employer</h2>
                   </li>
                </ul>
              </div>
           </div>
           <div id='demo'></div>
    </div>)
}
export default PrivateGame;