import { Link } from 'react-router-dom';
import '../styles/headers.css';
import bingo from '../images/bingo.jpg';
function Head(){
    /*    
    document.querySelector(".hamberger").addEventListener('click',()=>{
           document.querySelector(".mobilenavbar").style.display="block";
        })
        */
        return(<div className="mainHeader">
             
                               <div className='logo'>
                                <div></div>
                                <div></div>
                                <div></div>
                    <img src={bingo} alt=''/>            
                  <h1>BINGO BET</h1>
               </div> 
             
               <div className='headone'>
               
                <ul>
                    <li>
                        <Link to="/" style={{textDecoration:"none",color:"white"}}><button>Home</button></Link>
                    </li>
                   
                    <li>
                    <Link to="/auth/Singin" style={{textDecoration:"none",color:"white"}}><button style={{backgroundColor:'blue',color:'white'}}>Register</button></Link>
                    </li>
                    <li>
                        <Link to="/auth/Login" style={{textDecoration:"none",color:'white'}}><button style={{backgroundColor:'blue',color:'white'}}>Login</button></Link>
                    </li>
                    
                </ul>
               </div>
        </div>)
    }

export default Head;