//import Head from '../components/Header'
import userName from '../images/userName.jpg'
import '../styles/profile.css'
import { useNavigate } from 'react-router-dom'
import phoneNumber from '../images/phoneNumber.jpg'
import '../styles/message.css'
//import io from 'socket.io-client'
import { io } from 'socket.io-client'
import { useEffect } from 'react'

//const sock=io.connect('https://bingobet.net')
//const sock=io.connect('http://localhost:5000')
const sock=io.connect('https://game.bingobet.net')
//const sock=io.connect('https://game2free.bingobet.net')
//const sock=io.connect('https://game2free.bingobet.net:443',{
//  transports:['polling','websocket']
//})

let arx=[]
const Message=()=>{
  useEffect(()=>{
    sock.emit('allActiveMessagePersons',{
      name:sessionStorage.getItem('userName'),
      phone:sessionStorage.getItem('phone')
    })
  
    sock.on('activePersonRecieves',(data)=>{
      console.log('messaging data is comming....')
      console.log(data)
      arx.push(data)
     // let nar=[...new Set(bk)]
      console.log(arx.length)
      for(let k=0;k<arx.length;k++){

        let a=Math.floor(Math.random()*9)+1
        let b=Math.floor(Math.random()*9)+1
        let c=Math.floor(Math.random()*9)+1
        let im=document.createElement('button')
        im.textContent=`${data.name.slice(0,1)}`
        im.style.width='50px'
        im.style.height='50px'
        im.style.fontSize='20px'
        im.style.backgroundColor=`#${a}0${c}3${b}3`
        im.style.color='white'
        for(let k=150;k>-25;k--){
          setTimeout(()=>{
              for(let n=150;n>k;n--){
                  im.style.marginLeft=`${n}px`
              }
          })
      }
      
        document.querySelector('.onlinepersons').appendChild(im)
    
        let pt=document.createElement('p')
        pt.textContent=data.name
        pt.style.marginLeft='70px'
        pt.style.marginTop='-40px'
        for(let k=150;k>50;k--){
          setTimeout(()=>{
              for(let n=150;n>k;n--){
                  pt.style.marginLeft=`${n}px`
              }
          })
      }
        document.querySelector('.onlinepersons').appendChild(pt)
    
        let pp=document.createElement('p')
        pp.textContent=data.phone
        pp.style.marginLeft='70px'
        pp.style.marginTop='-10px'
        pp.style.color='#303030'
        for(let k=150;k>50;k--){
          setTimeout(()=>{
              for(let n=150;n>k;n--){
                  pp.style.marginLeft=`${n}px`
              }
          })
      }
      pp.style.borderBottom='1px solid yellow'
      pp.style.padding='5px'
        document.querySelector('.onlinepersons').appendChild(pp)

        let cc=document.createElement('p')
        cc.textContent='chat'
        cc.style.padding='5px'
        cc.style.borderRadius='10px'
        cc.style.backgroundColor='rgb(15, 183, 209)'
        cc.style.marginLeft='190px'
        cc.style.width='30px'
        cc.style.height='20px'
        cc.style.marginTop='-35px'
        cc.style.cursor='pointer'
        cc.addEventListener('click',()=>{
          alert(`chat with ${data.name}`)
          console.log(`chat with ${data.name}`)
        })
        document.querySelector('.onlinepersons').appendChild(cc)
        
        //now move the appended element in up ward
      }
      const heigh=document.querySelector('.onlinepersons').clientHeight
      document.querySelector('.onlinepersons').scroll(heigh,0)      
    })

  })
  let clk=0
  const navigate=useNavigate()
    return(<div>
       <div className='profilehead'>
        <img src={userName} alt='' onClick={()=>{
              if(clk===0){
                //document.querySelector('.playlist').style.marginLeft='500px';
                document.querySelector('.ssmobilenavbar').style.display='block';
                //document.querySelector('.shamberger div').style.backgroundColor='gray';
                //for(let n=-250;n<0;n++){
                    setTimeout(()=>{
                        for(let k=-250;k<0;k++){
                            document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                           // document.querySelector('.playlist').style.marginLeft=`${k}px`;
                       }
                    })
                //}
                clk++; 
            }
            else{
        
                //document.querySelector('.playlist').style.marginLeft='10px';
                //document.querySelector('.mobilenavbar').style.display='none';
                //document.querySelector('.shamberger div').style.backgroundColor='rgb(255, 0, 157)';
             //   for(let n=0;n>-250;n--){
                    setTimeout(()=>{
                        for(let k=0;k>-250;k--){
                            document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                           
                        }
                    })
             //   }
                clk=0; 
                document.querySelector('.ssmobilenavbar').style.display='none';
            }


        }}/>
        <h3>{`${sessionStorage.getItem('userName')}`}</h3>
        <h4>{`${sessionStorage.getItem('phone')}`}</h4>
        <h1>Chat With Us...</h1> 
        <ul>
        <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}`)}}>HOME</p> 
          </li>
          <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}/amount/20`)}}>GAME</p> 
          </li>
          <li>
          <p >DEPOSIT</p> 
          </li>
          <li>
          <p >BALANCE</p> 
          </li>
          <li>
            <p id='demo' ></p> 
          </li>
          
        </ul>   
       </div>
       <div className='ham' onClick={()=>{
    document.querySelector('.showhi').style.display='block'
  }}>
   <div></div>
   <div></div>
   <div></div>  
  </div>
  <div className='showhii'>
  
      <div className='showhi'>
      <button style={{position:'fixed',padding:'10px',backgroundColor:'black',color:'white'}} onClick={()=>{
        document.querySelector('.showhi').style.display='none'
      }}>x</button>
        <ul>
     <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}`)}}>HOME</p> 
          </li>
          <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}/amount/20`)}}>GAME</p> 
          </li>
          <li>
          <p >DEPOSIT</p> 
          </li>
          <li>
          <p >BALANCE</p> 
          </li>
         </ul> 
        </div>    
    </div>
     <div className='prof'>
        <div className='message'>
          <div className='mainmessage'>

          </div>
          <div className='sendingarea'>
             <input />
             <button>send</button> 
          </div>
        </div>
        
        <div className='activeperson'>
           <h2>Recent Messages</h2>
        </div>

        <div className='online'>
           <h2>Online Persons</h2>
           <div className='onlinepersons'></div>
        </div>
        
     </div>



     <div className='ssmobilenavbar'>
  
            <div className='ssmobilenavbarone'>
            <div className='cancle'>
               <p onClick={()=>{
                      /*
                      if(clk===0){
                          //document.querySelector('.playlist').style.marginLeft='500px';
                          document.querySelector('.ssmobilenavbar').style.display='block';
                          //document.querySelector('.shamberger div').style.backgroundColor='gray';
                          for(let n=-250;n<0;n++){
                              setTimeout(()=>{
                                  for(let k=-250;k<=n;k++){
                                      document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                                     // document.querySelector('.playlist').style.marginLeft=`${k}px`;
                                  }
                              })
                          }
                          clk++; 
                      }
                     */ 
                      //else{
                  
                          //document.querySelector('.playlist').style.marginLeft='10px';
                          //document.querySelector('.mobilenavbar').style.display='none';
                          //document.querySelector('.shamberger div').style.backgroundColor='rgb(255, 0, 157)';
                         // for(let n=0;n>-250;n--){
                              setTimeout(()=>{
                                  for(let k=0;k>=-250;k--){
                                      document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                                      //document.querySelector('.ssmobilenavbar').style.marginLeft=`${k}px`;
                                  }
                              })
                         // }
                          clk=0; 
                         setTimeout(()=>{
                          document.querySelector('.ssmobilenavbar').style.display='none';
                         },500) 
                          //document.querySelector('.ssmobilenavbar').style.display='none';
                      //}




               }}>x</p>
           </div>
           <br/><br/>
  <div className='profile'>
      <div className='profileOne'>
        <img src={userName} alt=""/>
         <div className='profileInfo'>
          <p style={{marginLeft:'30px',color:'white'}}>{sessionStorage.getItem('userName')}</p>
          <p><img src={phoneNumber} alt=''/> {sessionStorage.getItem('phone')}</p>
          <p style={{marginLeft:'100px',marginTop:'-15px'}}>$ {sessionStorage.getItem('currentBalance')}Birr </p>
         </div>
      </div>
    </div>            
    <ul>
                            <li  onClick={()=>{document.location=`./profile`}}>
                              Profile
                            </li>
                            <li onClick={()=>{document.location=`./playing-history`}}>
                            Playing History
                            </li>
                            <li onClick={()=>{document.location=`./winning-history`}}>
                             Winning History
                            </li>
                            <li onClick={()=>{document.location=`./transaction-history`}}>
                              Transaction History
                            </li>
                            <li onClick={()=>{document.location=`./message`}}>
                               Message
                            </li>
                            <li onClick={()=>{document.location=`./free-play`}}>
                              free play
                            </li>
                           
                        </ul>
                        <button style={{cursor:'pointer'}} onClick={()=>{
                          //sessionStorage.clear()
                           sessionStorage.clear('userName')
                           sessionStorage.clear('phone')
                           navigate('../auth/login')
                        }}>Logout</button>                   
 

                        </div>
                    </div>
        


    </div>)
}

export default Message;