import '../styles/home.css';
//import one from "../images/one.jpg";
import gamepictwo from '../images/gamepictwo.jpg';
//import coin2 from '../images/coin2.jpg';
//import gamepicone from '../images/gamepicone.jpg';
import bin1 from '../images/bin1.jpg';
import gamepicthree from '../images/gamepicthree.jpg';
import gamepicfour from '../images/gamepicfour.jpg';
//import manwinning1 from '../images/manwinning1.jpg';
import bin2 from '../images/bin2.jpg'
import manwinning2 from '../images/manwinning2.jpg';
import manwinning3 from '../images/manwinning3.jpg';
import manwinning4 from '../images/manwinning4.jpg';
import manwinning5 from '../images/manwinning5.jpg';
import b2 from '../images/b2.jpg'
import bing from '../images/bing.jpg';
import brule from '../images/brule.jpg';
import coin from '../images/coin.jpg';
import home from '../images/home.jpg';
import register from '../images/register.png';
import login from '../images/login.png';
import facebook from '../images/facebook.jpg'
import email from '../images/email.jpg'
import phoneNumber from '../images/phoneNumber.jpg'
import linkedin from '../images/linkedin.jpg'
import {Link} from 'react-router-dom';
//import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
//import { useState } from 'react';

//const sock=io.connect('https://bingobet.net')
//const sock=io.connect('http://localhost:5000')
//const sock=io.connect('https://game2free.bingobet.net')
//const sock=io.connect('https://game2free.bingobet.net',{
//const sock=io.connect('https://game.bingobet.net')
//  transports:['polling','websocket']
//})
/*
const sock=io.connect("https://game.bingobet.net",{
  "transports":['polling','websocket']
})
*/


function Home(){

  let vall=0;
    const navigate=useNavigate()
  // const {twonumber,setTwoNumber}=useState("")
 //  const t20=[],t50=[],t100=[],t150=[],t200=[],t250=[],t300=[],t500=[]
 
/*
   sock.on('activePlayers',(data)=>{
     //console.log(data)
     if(data.medeb==='20'){
       t20.push(data)
       document.querySelector('.tweentyy').innerHTML=`${t20.length}`
       document.querySelector('.price').innerHTML=`${t20.length*20}\nBirr`     
    }
     if(data.medeb==='50'){
        t50.push(data)
       document.querySelector('.fifty').innerHTML=`${t50.length}`
       document.querySelector('.price50').innerHTML=`${t50.length*50}\nBirr` 
    }

     if(data.medeb==='100'){
        t100.push(data)
       document.querySelector('.handred').innerHTML=`${t100.length}`
       document.querySelector('.price100').innerHTML=`${t100.length*100}\nBirr` 
    }
   if(data.medeb==='150'){
        t150.push(data)
       document.querySelector('.handredfifty').innerHTML=`${t150.length}`
       document.querySelector('.price150').innerHTML=`${t150.length*150}\nBirr` 
    }

   if(data.medeb==='200'){
       t200.push(data)
       document.querySelector('.twohandred').innerHTML=`${t200.length}`
       document.querySelector('.price200').innerHTML=`${t200.length*200}\nBirr` 
    }

   if(data.medeb==='250'){
        t250.push(data)
       document.querySelector('.twohandredfifty').innerHTML=`${t250.length}`
       document.querySelector('.price50').innerHTML=`${t250.length*250}\nBirr` 
    }

    if(data.medeb==='300'){
        t300.push(data)
       document.querySelector('.three').innerHTML=`${t300.length}`
       document.querySelector('.price300').innerHTML=`${t300.length*300}\nBirr` 
    }

    if(data.medeb==='500'){
        t500.push(data)
       document.querySelector('.fivehandred').innerHTML=`${t500.length}`
       document.querySelector('.price500').innerHTML=`${t500.length*500}\nBirr` 
    }



   })
  
   */
    let onee=0;
 setInterval(()=>{
    if(onee===0){
        document.querySelector(".ig").src=`${bin1}`
        onee++;
    }
  else if(onee===1){
        document.querySelector(".ig").src=`${bin2}`;
        onee++;
    }
 else if(onee===2){
        document.querySelector(".ig").src=`${gamepictwo}`
        onee++;
    }
 else if(onee===3){
  document.querySelector(".ig").src=`${manwinning2}`
  onee++;
 }   
 else if(onee===4){
    document.querySelector('.ig').src=`${gamepicthree}`
    onee++;
 }
 else if(onee===5){
    document.querySelector('.ig').src=`${manwinning5}`
    onee++;
 }
 else if(onee===6){
    document.querySelector(".ig").src=`${gamepicfour}`
    onee++;
 }   
  
 else if(onee===7){
    document.querySelector(".ig").src=`${manwinning4}`
    onee++;
 }
 else if(onee===8){
    document.querySelector(".ig").src=`${manwinning3}`
    onee=0;
 }

 },9000)
  
 //clearInterval(3000);
/* 
   setTimeout(()=>{
    for(let i=10;i<800;i++){
        setTimeout(()=>{
            for(let j=0;j<i;j++){
                document.querySelector('.introOne').style.marginLeft=`${j}px`;
            }
        })
    }

    for(let i=800;i>200;i--){
        setTimeout(()=>{
            for(let j=800;j>i;j--){
                document.querySelector('.introOne').style.marginLeft=`${j}px`;
            }
        })
    }
   },5000) 
*/
   //adding round values over her.....
   let clk=0;
 
    return(<div className="routes" >
         <div className='hamberger' onClick={()=>{
            //let clk=0;
            if(clk===0){
                document.querySelector('.playlist').style.marginLeft='500px';
                document.querySelector('.mobilenavbar').style.display='block';
                document.querySelector('.hamberger div').style.backgroundColor='gray';
                for(let n=-250;n<0;n++){
                    setTimeout(()=>{
                        for(let k=-250;k<=n;k++){
                            document.querySelector('.mobilenavbarone').style.marginLeft=`${k}px`;
                           // document.querySelector('.playlist').style.marginLeft=`${k}px`;
                        }
                    })
                }
                clk++; 
            }
            else{
        
                document.querySelector('.playlist').style.marginLeft='10px';
                //document.querySelector('.mobilenavbar').style.display='none';
                document.querySelector('.hamberger div').style.backgroundColor='rgb(255, 0, 157)';
                for(let n=0;n>-250;n--){
                    setTimeout(()=>{
                        for(let k=0;k>=n;k--){
                            document.querySelector('.mobilenavbarone').style.marginLeft=`${k}px`;
                           
                        }
                    })
                }
                clk=0; 
                document.querySelector('.mobilenavbar').style.display='none';
            }
         }}>
                <div></div>
                <div></div>
                <div></div>
            </div>
           <br/><br/><br/> 
        <div className="gentle">
            <img className="ig" src={bin1} alt="" style={{width:"1200px",height:"700px"}}/>
        </div>
        
        <div className='intro'>
           <div className="introOne">
               <h1>Play To Get 1,000 Coin</h1>
              <p>And Get Paid<br/>Only your phone number is required</p>
              <button><Link to="/auth/login" style={{color:"white",textDecoration:"none"}}>Register to Win</Link></button>
            </div> 
            <div className="introThree">
               <h1>Play With Your Familly<br/> At Home Freelly</h1>
              <p>Only your phone number is required <br/>
                 Be Entertain Freelly<br/>
                 only 2 vertical close<br/>
                 will win per game 
              </p>
            </div> 
            <div className="introOne">
               <h1>8 Rounds, 1 coin for each Round</h1>
              <p>Unique Game For finall Round</p>
            </div> 



            
            <div className="introOne">
               <div className='footer'>
                   <div className='footerone'>
                     <button onClick={()=>{
                       
                       if(vall===0){ 
                         for(let i=-100;i>-380;i--){
                          setTimeout(()=>{
                            for(let k=-100;k>=i;k--){
                              document.querySelector('.footer').style.marginTop=`${k}px`
                             
                            }

                          })
                         }
                        vall++  
                      }else if(vall===1){
                         document.querySelector('.footer').style.marginTop="-250px"
                         vall=0
                      }
                     }}>^</button>  
                   </div>
                 <div className='footertw'>
                     <h3>We Are In</h3>
                   <img src={facebook} alt=""/>
                   <p>facebook</p> 
                   <img src={linkedin} alt=""/>
                   <p>LinkedIn</p>
                 </div>  

                 <div className='footertwo'>
                     <h3>Email</h3>
                   <img src={email} alt=""/>
                   <p>info@bingobet.net</p><br/>
                   <p>belay.unix@gmail.com</p>
                 </div>  
              
                 <div className='footerthree'>
                     <h3>phone</h3>
                   <img src={phoneNumber} alt=""/>
                   <p>+251900766703</p><br/>
                 
                 </div>  

                  <div className='footerfour'>
                  <h3>Location</h3>
                  <br/><br/>
                   <p>Addis Ababa/Ethiopia</p><br/>
                  </div>
                   
                  

        </div>
            </div> 
        
          
<div className='mobilenavbar'>
    <div className='xbutton' onClick={()=>{
        
        document.querySelector('.playlist').style.marginLeft='10px';
        //document.querySelector('.mobilenavbar').style.display='none';
        document.querySelector('.hamberger div').style.backgroundColor='rgb(255, 0, 157)';
        for(let n=0;n>-250;n--){
            setTimeout(()=>{
                for(let k=0;k>=n;k--){
                    document.querySelector('.mobilenavbarone').style.marginLeft=`${k}px`;
                   
                }
            })
        }
        clk=0; 
        document.querySelector('.mobilenavbar').style.display='none';
    
    }}>
        <div></div>
    </div>
    <div className='x2button' onClick={()=>{
        document.querySelector('.playlist').style.marginLeft='10px';
        //document.querySelector('.mobilenavbar').style.display='none';
        document.querySelector('.hamberger div').style.backgroundColor='rgb(255, 0, 157)';
        for(let n=0;n>-250;n--){
            setTimeout(()=>{
                for(let k=0;k>=n;k--){
                    document.querySelector('.mobilenavbarone').style.marginLeft=`${k}px`;
                   
                }
            })
        }
        clk=0; 
        document.querySelector('.mobilenavbar').style.display='none';
    }}>
        <div></div>
    </div>
<br/><br/>
    <div className='mobilenavbarone'>
            <ul>
                    <li>
                       <img src={home} alt=''/> <Link to="/" style={{textDecoration:"none",color:"white"}}><button>Home</button></Link>
                    </li>
                  
                    <li>
                    <img src={register} alt=''/><Link to="/auth/Singin" style={{textDecoration:"none",color:"white"}}><button style={{backgroundColor:'blue',color:'white'}}>Register</button></Link>
                    </li>
                    <li>
                       <img src={login} alt=''/> <Link to="/auth/Login" style={{textDecoration:"none",color:'white'}}><button style={{backgroundColor:'blue',color:'white'}}>Login</button></Link>
                    </li>
                    
                </ul>
            
                </div>
        
            </div>

        </div>
        <div className='playlist'>
            <div className="playlistOne">
              <h2>About The Game</h2>    
            </div>  
       <div className='round'>
         <div className='button--loadingg'>
              <div className='hpy'>
                 <h1 onClick={()=>{
                  document.querySelector('.hp').style.display='block'
                 }}>How To Play
                     <img src={b2} alt=''/>
                 </h1>
               
              </div>

              <div className='hpyy'>
                 <h1 onClick={()=>{
                  document.querySelector('.tgame').style.display='block'
                 }}>The Game
                    <img src={bing} alt=''/>
                 </h1>
        
              </div>

              <div className='hpy'>
                 <h1 onClick={()=>{
                  document.querySelector('.rule').style.display='block'
                 }}>Regulations
                   <img src={brule} alt=''/>
                 </h1>
        
              </div>

              <div className='hpyyy'>
                 <h1 onClick={()=>{
                  document.querySelector('.winn').style.display='block'
                 }}>Winner Price
                    <img src={coin} alt=''/>
                 </h1>
        
              </div>
        </div> 

        
            <div className='hp'>
             <div className='hpp'> 
               <button onClick={()=>{
                document.querySelector('.hp').style.display='none';
               }}>x</button>
                       <h1>How to play</h1>
                 <p>The game has lot's of cards<br/>
                    with specific numbers. you      <br/>
                    can choose any of them as      <br/>
                    long as it is not taken by     <br/>
                    other players, after selection  <br/>
                    the system will call random   <br/>
                    numbers, if your selected card  <br/>
                    with only 2 columns (which means<br/>
                    FROM B,I,N,G,O)with all verticals<br/>
                    are being called you will be winner.
                  </p>
              </div>  
          </div>  


          <div className='rule'>
             <div className='ruleone'> 
               <button onClick={()=>{
                document.querySelector('.rule').style.display='none';
               }}>x</button>
                    <h1>Rule and Regulations</h1>
                 <p>1.The company has responisble<br/>
                      for paying the proper money<br/>
                      for the winners with in one<br/>
                      month.<br/>
                    2.User money will be payed only<br/>
                      if the money is more than 1,000<br/>
                      ETB.
                 
                  </p>
              </div>  
          </div>  
   

          <div className='winn'>
             <div className='winnone'> 
               <button onClick={()=>{
                document.querySelector('.winn').style.display='none';
               }}>x</button>
                    <h1>Winning Price</h1>
                 <p>
                    1.Each game round has winners.<br/>
                    In one game, winners will get<br/>
                    one coin, if you pass 1's round,<br/>
                    wich means if you winn 10 game,<br/>
                    you will get 10 coins then your<br/>
                    10 coin can be change into 1 ETB.<br/><br/><br/>
                    2.If you winn 10 game for every <br/>
                    round, you will be join last round<br/>
                    and if you winn,every player will <br/>
                    give their coin for you. the coin<br/>
                    will be changed in to Birr.<br/>
                    3.If you loss the last game you will<br/>
                    give every coin you gather from the<br/>
                    game.But if you loss other then last<br/>
                    round, your coin will stay the same.
                   
                  </p>
              </div>  
          </div>


          <div className='tgame'>
             <div className='tgameone'> 
               <button onClick={()=>{
                document.querySelector('.tgame').style.display='none';
               }}>x</button>
                    <h1> The Game</h1>
                 <p>
                    The game is so interesting,<br/>
                    because you are not playing<br/>
                    alone,tunes of people will<br/>
                    play the game at the same <br/>
                    time.
                  </p>
              </div>  
          </div>


<div className='jnt'>
  <button onClick={()=>{
    navigate('./auth/Singin')
  }}>join now</button>
</div>

<div className='jntt'>
  <button onClick={()=>{
     navigate('./auth/login')
  }}>Already joined</button>
</div>

            </div>
          </div> 
      
       <div id='demo' type='hidden'></div>
        
    </div>)
}

export default Home;