import gamepictwo from '../images/gamepictwo.jpg';
import Users from "../components/AllRegisterdUsers";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
//import {createStore} from 'redux';
//import reducer from '../reducers/mainReducer';

//const store=createStore(reducer)

const User=()=>{
    const navigate=useNavigate()
    useEffect(()=>{
       
        document.querySelector('.gentle').style.display='none'
        if(!(sessionStorage.getItem('userName') && sessionStorage.getItem('phone'))){
            navigate('/auth/login');  
        }
    },[navigate])
   
  
   return(<div>
    <div className="gentle">
            <img type='hidden' className="ig" src={gamepictwo} alt="" style={{width:"1200px",height:"700px"}}/>
        </div>

<p id='demo'>g</p>      
    <Users />  
 
   </div>)
}

export default User;
