import authAxois from './requiestHandler';
//import axios from 'axios';
import '../styles/allroutes.css';
import '../styles/login.css';
import gamepictwo from '../images/gamepictwo.jpg';
//import coin2 from '../images/coin2.jpg';
import gamepicone from '../images/gamepicone.jpg';
import gamepicthree from '../images/gamepicthree.jpg';
import gamepicfour from '../images/gamepicfour.jpg';
import manwinning1 from '../images/manwinning1.jpg';
import manwinning2 from '../images/manwinning2.jpg';
import manwinning3 from '../images/manwinning3.jpg';
import manwinning4 from '../images/manwinning4.jpg';
import phoneNumber from '../images/phoneNumber.jpg';
import pwdd from '../images/pwdd.jpg';
import home from '../images/home.jpg';
import register from '../images/register.png';
import login from '../images/login.png';
import facebook from '../images/facebook.jpg'
import linkedin from '../images/linkedin.jpg'
import email from '../images/email.jpg'
import {Link, useNavigate} from 'react-router-dom';
function Login(){
    const navigate=useNavigate();
  let onee=0;
  let clk=0;
  let vall=0;
  setInterval(()=>{
     if(onee===0){
         document.querySelector(".ig").src=`${gamepicone}`
         onee++;
     }
   else if(onee===1){
         document.querySelector(".ig").src=`${manwinning1}`;
         onee++;
     }
  else if(onee===2){
         document.querySelector(".ig").src=`${gamepictwo}`
         onee++;
     }
  else if(onee===3){
   document.querySelector(".ig").src=`${manwinning2}`
   onee++;
  }   
  else if(onee===4){
     document.querySelector('.ig').src=`${gamepicthree}`
     onee++;
  }
  else if(onee===5){
     document.querySelector('.ig').src=`${manwinning3}`
     onee++;
  }
  else if(onee===6){
   document.querySelector(".ig").src=`${gamepicfour}`
   onee++;
}   
 
else if(onee===7){
   document.querySelector(".ig").src=`${manwinning4}`
   onee=0;
} 
  },9000)
   
    return(<div className='routes' id="mainLogin">
          <div className="gentle">
            <img className="ig" src={gamepictwo} alt="" style={{width:"1200px",height:"700px"}}/>
        </div>


        <div className='shamberger' onClick={()=>{
            //let clk=0;
            if(clk===0){
                //document.querySelector('.playlist').style.marginLeft='500px';
                document.querySelector('.smobilenavbar').style.display='block';
                document.querySelector('.shamberger div').style.backgroundColor='gray';
                for(let n=-250;n<0;n++){
                    setTimeout(()=>{
                        for(let k=-250;k<=n;k++){
                            document.querySelector('.smobilenavbarone').style.marginLeft=`${k}px`;
                           // document.querySelector('.playlist').style.marginLeft=`${k}px`;
                        }
                    })
                }
                clk++; 
            }
            else{
        
                //document.querySelector('.playlist').style.marginLeft='10px';
                //document.querySelector('.mobilenavbar').style.display='none';
                document.querySelector('.shamberger div').style.backgroundColor='rgb(255, 0, 157)';
                for(let n=0;n>-250;n--){
                    setTimeout(()=>{
                        for(let k=0;k>=n;k--){
                            document.querySelector('.smobilenavbarone').style.marginLeft=`${k}px`;
                           
                        }
                    })
                }
                clk=0; 
                document.querySelector('.smobilenavbar').style.display='none';
            }
         }}>
           <div></div>
           <div></div>
           <div></div>
       </div>



        <div className='loginForm'>
           <div className='login'>
             <h1>Login</h1>
         <div className="input-field">
              <input id='phone' className="pwd" required/>
                <div className="apn"></div>
             <label>phone </label>
         </div>
       <div className='addimg'><img src={phoneNumber} alt='' style={{borderRadius:"60px"}}/></div>  
       <div className="lgn">
       </div><br/><br/>
       <div className="input-field">
              <input id='password' className="pwd" v-model="password" type="password" required/>
                <div className="apn"></div>
             <label>password</label>
         </div>
         <div className='addimg'><img src={pwdd} alt='' style={{borderRadius:"60px"}}/></div>
       <div className="lgn">
       </div>
      <br/> 
           <button className='button' style={{height:'50px'}} onClick={()=>{
            document.querySelector('.button').classList.add('button--loading')
            document.querySelector('.singtxtt').style.display='none';
            const loginCredential={
                phone:document.querySelector('#phone').value,
                password:document.querySelector('#password').value
            }
         /**    
          if(sessionStorage.getItem('phone')===loginCredential.phone){
            navigate(`/user/${localStorage.getItem('phone')}`)
          }
          else{
            alert('phone number password combination is not correct')
          }
          **/  

          
            authAxois.post('/user/login',loginCredential).then((res)=>{
               console.log('response is comming from server side')  
               console.log(res)
               if(res.data.state==='20xx'){
                 //sessionStorage.setItem('userName',res.data.data[0].fullname)
                 //sessionStorage.setItem('phone',res.data.data[0].phone)
                 //sessionStorage.setItem('currentBalance',res.data.data[0].currentBalance)
                
                 const nam=JSON.parse(res.data.data)
                 sessionStorage.setItem('userName',nam.name)
                 sessionStorage.setItem('phone',loginCredential.phone)
                 sessionStorage.setItem('currentBalance',res.data.data[0].currentBalance)
                 navigate(`/user/${sessionStorage.getItem('userName')}`)
               } else{
                alert('wrong phone number password combination')
               }
            }).catch((e)=>{
                console.log('error has been ocured during sending login request')
                console.log(e)
            })

  

           }}><span className='button__text'><p className='singtxtt' style={{fontFamily:'arial',marginTop:'-10px'}}>Login</p></span></button><br/><br/>
           <Link to="/auth/singin" style={{color:"black",textDecoration:"none"}}><button>Create New Acount</button></Link>  
           </div>
        </div>
<p id='demo'></p>


<div className='footerr'>
                   <div className='footerone'>
                   <button className="ddx" onClick={()=>{
                       
                       if(vall===0){ 
                         for(let i=300;i<450;i++){
                          setTimeout(()=>{
                            for(let k=300;k<=i;k++){
                              document.querySelector('.footerr').style.marginTop=`${k}px`
                             // document.querySelector('.twothing').style.marginLeft=`${k}px`
                             // document.querySelector('.threething').style.marginLeft=`${k}px`
                            }

                          })
                         }
                        
                        document.querySelector('.ddx').innerHTML="^" 
                        vall++  
                      }else if(vall===1){
                        for(let i=450;i>=330;i--){
                          setTimeout(()=>{
                            for(let k=450;k>=i;k--){
                              document.querySelector('.footerr').style.marginTop=`${k}px`
                             // document.querySelector('.twothing').style.marginLeft=`${k}px`
                              //document.querySelector('.threething').style.marginLeft=`${k}px`
                            }
                          })
                         
                         }


                         //document.querySelector('.footerr').style.marginTop="-250px"
                         document.querySelector('.ddx').innerHTML="V"
                         document.querySelector('.footerr').style.height='500px'
                         document.querySelector('.footerthree').style.width='400px' 
                         vall=0
                      }
                     }}>V</button>  
                   </div>
                 <div className='footertw'>
                     <h3>We Are In</h3>
                   <img src={facebook} alt=""/>
                   <p>facebook</p> 
                   <img src={linkedin} alt=""/>
                   <p>LinkedIn</p>
                 </div>  

                 <div className='footertwo'>
                     <h3>Email</h3>
                   <img src={email} alt=""/>
                   <p>info@bingobet.net</p><br/>
                   <p>belay.unix@gmail.com</p>
                 </div>  
              
                 <div className='footerthree'>
                     <h3>phone</h3>
                   <img src={phoneNumber} alt=""/>
                   <p>+251900766703</p><br/>
                 
                 </div>  

                  <div className='footerfour'>
                  <h3>Location</h3>
                  <br/><br/>
                   <p>Addis Ababa/Ethiopia</p><br/>
                  </div>
                   
                  
        </div>




        <div className='smobilenavbar'>
    <div className='smobilenavbarone'>
            <ul>
                    <li>
                       <img src={home} alt=''/> <Link to="/" style={{textDecoration:"none",color:"white"}}><button>Home</button></Link>
                    </li>
                 
                    <li>
                    <img src={register} alt=''/><Link to="/auth/Singin" style={{textDecoration:"none",color:"white"}}><button style={{backgroundColor:'blue',color:'white'}}>Register</button></Link>
                    </li>
                    <li>
                       <img src={login} alt=''/> <Link to="/auth/Login" style={{textDecoration:"none",color:'white'}}><button style={{backgroundColor:'blue',color:'white'}}>Login</button></Link>
                    </li>
                    
                </ul>
                </div>
            </div>

        </div>);
};
export default Login;