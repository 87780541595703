import gamepictwo from '../images/gamepictwo.jpg';
import Users from "../components/AllRegisterdUsers";
import { useNavigate,useParams } from 'react-router-dom';
import { useEffect } from 'react'; 
//import {createStore} from 'redux';
//import reducer from '../reducers/mainReducer';

//const store=createStore(reducer)


const Userrr=()=>{
    const {id}=useParams()
    const navigate=useNavigate()
    useEffect(()=>{
       
        document.querySelector('.gentle').style.display='none'
        if(id==='50' || id==='100' || id==='150' || id==='200'){
           document.querySelector('.roundlevel').style.display='block';
        }
        if(!(sessionStorage.getItem('userName') && sessionStorage.getItem('phone'))){
            navigate('/auth/login');  
        }
    },[navigate,id])
   
  
   return(<div>
    <div className="gentle">
            <img type='hidden' className="ig" src={gamepictwo} alt="" style={{width:"1200px",height:"700px"}}/>
        </div>

<p id='demo'>g</p>      
    <Users />  
    <div className='roundlevel'>
    <div className='levelmessage'>
        <button className='btnx' onClick={()=>{
            document.querySelector('.roundlevel').style.display='none'
            navigate(`../user/${sessionStorage.getItem('userName')}/20`)
        }}>x</button>
       <h1>Good Luck</h1>
       <img src={gamepictwo} alt=''/>
       <p>To play these round You need To have 10  coins,<br/>
         To Get That you have to win 10 free games<br/>
           
       </p>
       <button className='btnxx' onClick={()=>{
        document.querySelector('.roundlevel').style.display='none'
        navigate(`../user/${sessionStorage.getItem('userName')}/20`)
       }}>Back To The Game</button>
    </div>
</div>
   </div>)
}

export default Userrr;
