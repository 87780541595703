//import Head from '../components/Header'
import userName from '../images/userName.jpg'
import '../styles/profile.css'
import '../styles/whistory.css'
import { useNavigate } from 'react-router-dom'
import phoneNumber from '../images/phoneNumber.jpg'
import authAxois from './requiestHandler'
import { useEffect } from 'react'
const WinningHistory=()=>{
  let clk=0
  const navigate=useNavigate()

  const phis=[]
  useEffect(()=>{
   
    if(sessionStorage.getItem('phone') && sessionStorage.getItem('userName')){ 
  
      authAxois.get(`/users/winhistory/info`).then((rs)=>{
      for(let i=0;i<rs.data.length;i++){
        if(sessionStorage.getItem('phone')===rs.data[i].winnersid){     
            //console.log(rs)
            phis.push(rs.data[i])
            //console.log(phis)
            const tk=document.createElement('p')
            tk.textContent=rs.data[i].winnersdate
            tk.style.color='yellow'
            tk.style.padding='10px'
            tk.style.borderBottom='1px solid red'
            document.querySelector('.ont').appendChild(tk)

            const tkn=document.createElement('p')
            tkn.textContent=rs.data[i].winnerstime
            tkn.style.color='yellow'
            tkn.style.padding='10px'
            tkn.style.borderBottom='1px solid red'
            document.querySelector('.twt').appendChild(tkn)

            const tkm=document.createElement('p')
            tkm.textContent=rs.data[i].medeb
            tkm.style.color='yellow'
            tkm.style.padding='10px'
            tkm.style.borderBottom='1px solid red'
            document.querySelector('.tht').appendChild(tkm)

            const tkl=document.createElement('p')
            tkl.textContent=rs.data[i].numberofplayers
            tkl.style.color='yellow'
            tkl.style.padding='10px'
            tkl.style.borderBottom='1px solid red'
            document.querySelector('.fot').appendChild(tkl)

            const tkll=document.createElement('p')
            tkll.textContent=rs.data[i].playercard
            tkll.style.color='yellow'
            tkll.style.padding='10px'
            tkll.style.borderBottom='1px solid red'
            document.querySelector('.fott').appendChild(tkll)

            const tklll=document.createElement('p')
            tklll.textContent=rs.data[i].winnersprice
            tklll.style.color='yellow'
            tklll.style.padding='10px'
            tklll.style.borderBottom='1px solid red'
            document.querySelector('.fottt').appendChild(tklll)
        }

          }
      }).catch((e)=>e)
     
    }else{
      navigate('/auth/login')
    }
   })


    return(<div>
       <div className='profilehead'>
        <img src={userName} alt='' onClick={()=>{
              if(clk===0){
                //document.querySelector('.playlist').style.marginLeft='500px';
                document.querySelector('.ssmobilenavbar').style.display='block';
                //document.querySelector('.shamberger div').style.backgroundColor='gray';
                //for(let n=-250;n<0;n++){
                    setTimeout(()=>{
                        for(let k=-250;k<0;k++){
                            document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                           // document.querySelector('.playlist').style.marginLeft=`${k}px`;
                       }
                    })
                //}
                clk++; 
            }
            else{
        
                //document.querySelector('.playlist').style.marginLeft='10px';
                //document.querySelector('.mobilenavbar').style.display='none';
                //document.querySelector('.shamberger div').style.backgroundColor='rgb(255, 0, 157)';
             //   for(let n=0;n>-250;n--){
                    setTimeout(()=>{
                        for(let k=0;k>-250;k--){
                            document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                           
                        }
                    })
             //   }
                clk=0; 
                document.querySelector('.ssmobilenavbar').style.display='none';
            }


        }}/>
        <h3>{`${sessionStorage.getItem('userName')}`}</h3>
        <h4>{`${sessionStorage.getItem('phone')}`}</h4>
        <h1>Winning History</h1> 
        <ul>
        <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}`)}}>HOME</p> 
          </li>
          <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}/amount/20`)}}>GAME</p> 
          </li>
          <li>
          <p >DEPOSIT</p> 
          </li>
          <li>
          <p >BALANCE</p> 
          </li>
          <li>
            <p id='demo' ></p> 
          </li>
          
        </ul>   
       </div>
   
  <div className='showhii'>
  
      <div className='showhi'>
      <button style={{position:'fixed',padding:'10px',backgroundColor:'black',color:'white'}} onClick={()=>{
        document.querySelector('.showhi').style.display='none'
      }}>x</button>
        <ul>
     <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}`)}}>HOME</p> 
          </li>
          <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}/amount/20`)}}>GAME</p> 
          </li>
          <li>
          <p >DEPOSIT</p> 
          </li>
          <li>
          <p >BALANCE</p> 
          </li>
         </ul> 
        </div>    
    </div>
     <div className='prof'>
       <div className='nic'>
       <ul>
        <li><h1>Winning Date</h1></li>
        <li> <h1>Winning Time</h1></li>
        <li> <h1>Winning Medeb</h1></li>
        <li><h1>Number of Players</h1></li>
        <li> <h1>Winning Card</h1></li>
        <li> <h1>Winning price</h1></li>
      </ul>
       
     </div>
         <div className='ont'></div>
         <div className='twt'></div>
         <div className='tht'></div>
         <div className='fot'></div>
         <div className='fott'></div>
         <div className='fottt'></div> 
     </div>



     <div className='ssmobilenavbar'>
  
            <div className='ssmobilenavbarone'>
            <div className='cancle'>
               <p onClick={()=>{
                      /*
                      if(clk===0){
                          //document.querySelector('.playlist').style.marginLeft='500px';
                          document.querySelector('.ssmobilenavbar').style.display='block';
                          //document.querySelector('.shamberger div').style.backgroundColor='gray';
                          for(let n=-250;n<0;n++){
                              setTimeout(()=>{
                                  for(let k=-250;k<=n;k++){
                                      document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                                     // document.querySelector('.playlist').style.marginLeft=`${k}px`;
                                  }
                              })
                          }
                          clk++; 
                      }
                     */ 
                      //else{
                  
                          //document.querySelector('.playlist').style.marginLeft='10px';
                          //document.querySelector('.mobilenavbar').style.display='none';
                          //document.querySelector('.shamberger div').style.backgroundColor='rgb(255, 0, 157)';
                         // for(let n=0;n>-250;n--){
                              setTimeout(()=>{
                                  for(let k=0;k>=-250;k--){
                                      document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                                      //document.querySelector('.ssmobilenavbar').style.marginLeft=`${k}px`;
                                  }
                              })
                         // }
                          clk=0; 
                         setTimeout(()=>{
                          document.querySelector('.ssmobilenavbar').style.display='none';
                         },500) 
                          //document.querySelector('.ssmobilenavbar').style.display='none';
                      //}




               }}>x</p>
           </div>
           <br/><br/>
  <div className='profile'>
      <div className='profileOne'>
        <img src={userName} alt=""/>
         <div className='profileInfo'>
          <p style={{marginLeft:'30px',color:'white'}}>{sessionStorage.getItem('userName')}</p>
          <p><img src={phoneNumber} alt=''/> {sessionStorage.getItem('phone')}</p>
          <p style={{marginLeft:'100px',marginTop:'-15px'}}>$ {sessionStorage.getItem('currentBalance')}Birr </p>
         </div>
      </div>
    </div>            
    <ul>

                       <li onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}`)}}>
                          HOME 
                        </li>
                          <li onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}/amount/20`)}}>
                            GAME 
                          </li>
                            <li  onClick={()=>{document.location=`./profile`}}>
                              Profile
                            </li>
                            <li onClick={()=>{document.location=`./playing-history`}}>
                            Playing History
                            </li>
                            <li onClick={()=>{document.location=`./winning-history`}}>
                             Winning History
                            </li>
                            <li onClick={()=>{document.location=`./transaction-history`}}>
                              Transaction History
                            </li>
                            <li onClick={()=>{document.location=`./message`}}>
                               Message
                            </li>
                            <li onClick={()=>{document.location=`./free-play`}}>
                              free play
                            </li>
                           
                        </ul>
                        <button style={{cursor:'pointer'}} onClick={()=>{
                          //sessionStorage.clear()
                           sessionStorage.clear('userName')
                           sessionStorage.clear('phone')
                           navigate('../auth/login')
                        }}>Logout</button>                   
 

                        </div>
                    </div>
        


    </div>)
}

export default WinningHistory;