import axios from 'axios';

//const accessToken=''
const authAxois=axios.create({
    //baseURL:'https://bingobet.net/api'
    baseURL:'https://game.bingobet.net',
     //baseURL:'http://localhost:5000',
    //baseURL:'https://game2free.bingobet.net',
   // headers:{
      //  Authorization:`Bearer ${accessToken}`,
       // Access-Control-Allow-Origin: *

   // }
   // withCredentials: true
})

export default authAxois;