//import Head from '../components/Header'
import userName from '../images/userName.jpg'
import '../styles/profile.css'
import '../styles/phistory.css'
import { useNavigate } from 'react-router-dom'
import phoneNumber from '../images/phoneNumber.jpg'
import manwinning3 from '../images/manwinning3.jpg';
import { useEffect } from 'react'
import authAxois from './requiestHandler'
const PlayingHistory=()=>{
  let clk=0
  const navigate=useNavigate()
  const dt={
    phone:sessionStorage.getItem('phone'),
    name:sessionStorage.getItem('userName')
  }
  const phis=[]
  useEffect(()=>{
   
    if(sessionStorage.getItem('phone') && sessionStorage.getItem('userName')){ 
  
      authAxois.get(`/users/playhistory/info`,dt).then((rs)=>{
       //phis=rs.data
      for(let i=0;i<rs.data.length;i++){
       
        if(sessionStorage.getItem('phone')===rs.data[i].playerphone){     
            //console.log(rs)
            phis.push(rs.data[i])
            //console.log(phis)
            const dd=document.querySelector('.playhis')
            dd.style.position='fixed'
            dd.style.width='310px';
            dd.style.height='430px'
            dd.style.backgroundColor='#303030';
            dd.style.marginTop='60px';
            dd.style.padding='10px'
            dd.style.overFlow='scroll'

            const dom=document.createElement('p');
            dom.textContent=rs.data[i].playingdate;
            dom.style.color='white';
            dd.appendChild(dom);


            const domm=document.createElement('p');
            domm.textContent=rs.data[i].playingtime.slice(0,13);
            domm.style.color='white';
            domm.style.marginLeft='150px';
            domm.style.marginTop='-50px';
            domm.style.fontSize='10px';
            domm.style.padding='15px';
            dd.appendChild(domm);

            const dommm=document.createElement('p')
            dommm.textContent=rs.data[i].playercard;
            dommm.style.color='white';
            dommm.style.marginLeft='280px';
            dommm.style.marginTop='-50px';
            dommm.style.padding='10px';
            dd.appendChild(dommm);

            const dommmm=document.createElement('p')
            dommmm.textContent=rs.data[i].playinground;
            dommmm.style.color='white';
            dommmm.style.marginLeft='350px';
            dommmm.style.marginTop='-50px';
            dommmm.style.padding='10px';
            dd.appendChild(dommmm);

        }

          }
      }).catch((e)=>e)
      
     
    }else{
      navigate('/auth/login')
    }
   })
   

  
    return(<div>
       <div className='profilehead'>
        <div className='nvb'>
          <div></div>
          <div></div>
          <div></div> 
       </div>
        <img src={userName} alt='' onClick={()=>{
              if(clk===0){
                //document.querySelector('.playlist').style.marginLeft='500px';
                document.querySelector('.ssmobilenavbar').style.display='block';
                //document.querySelector('.shamberger div').style.backgroundColor='gray';
                //for(let n=-250;n<0;n++){
                    setTimeout(()=>{
                        for(let k=-250;k<0;k++){
                            document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                           // document.querySelector('.playlist').style.marginLeft=`${k}px`;
                       }
                    })
                //}
                clk++; 
            }
            else{
        
                //document.querySelector('.playlist').style.marginLeft='10px';
                //document.querySelector('.mobilenavbar').style.display='none';
                //document.querySelector('.shamberger div').style.backgroundColor='rgb(255, 0, 157)';
             //   for(let n=0;n>-250;n--){
                    setTimeout(()=>{
                        for(let k=0;k>-250;k--){
                            document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                           
                        }
                    })
             //   }
                clk=0; 
                document.querySelector('.ssmobilenavbar').style.display='none';
            }


        }}/>
        <h3>{`${sessionStorage.getItem('userName')}`}</h3>
        <h4>{`${sessionStorage.getItem('phone')}`}</h4>
        <h1>Playing History</h1> 
        <ul>
        <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}`)}}>HOME</p> 
          </li>
          <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}/amount/20`)}}>GAME</p> 
          </li>
          <li>
          <p >DEPOSIT</p> 
          </li>
          <li>
          <p >BALANCE</p> 
          </li>
          <li>
            <p id='demo' ></p> 
          </li>
          
        </ul>   
       </div>

  <div className='showhii'>
  
      <div className='showhi'>
      <button style={{position:'fixed',padding:'10px',backgroundColor:'black',color:'white'}} onClick={()=>{
        document.querySelector('.showhi').style.display='none'
      }}>x</button>
        <ul>
     <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}`)}}>HOME</p> 
          </li>
          <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}/amount/20`)}}>GAME</p> 
          </li>
          <li>
          <p >DEPOSIT</p> 
          </li>
          <li>
          <p >BALANCE</p> 
          </li>
         </ul> 
        </div>    
    </div>
    
     <div className='prof'>
     <div className='nic'>
      <ul>
        <li><h1>Date</h1></li>
        <li> <h1>Time</h1></li>
        <li><h1>Card</h1></li>
        <li> <h1>Coins</h1></li>
      </ul>
      <div className='playhis'></div>
       
     </div>

  <div className='picc'>
    <img src={manwinning3} alt=''/>
    <p>Play Up To 8 Round And If You Win the 
       last round, your coin will change in to Money.<br/>
       for each round you should win 10 game 
       in that case you get 10 coins after<br/>
       then, on the final round only winner at 
       final round will play, and only the final winners<br/>
       will get the price.
    </p>  
  </div>      
     </div>
   


     <div className='ssmobilenavbar'>
  
            <div className='ssmobilenavbarone'>
            <div className='cancle'>
               <p onClick={()=>{
                      /*
                      if(clk===0){
                          //document.querySelector('.playlist').style.marginLeft='500px';
                          document.querySelector('.ssmobilenavbar').style.display='block';
                          //document.querySelector('.shamberger div').style.backgroundColor='gray';
                          for(let n=-250;n<0;n++){
                              setTimeout(()=>{
                                  for(let k=-250;k<=n;k++){
                                      document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                                     // document.querySelector('.playlist').style.marginLeft=`${k}px`;
                                  }
                              })
                          }
                          clk++; 
                      }
                     */ 
                      //else{
                  
                          //document.querySelector('.playlist').style.marginLeft='10px';
                          //document.querySelector('.mobilenavbar').style.display='none';
                          //document.querySelector('.shamberger div').style.backgroundColor='rgb(255, 0, 157)';
                         // for(let n=0;n>-250;n--){
                              setTimeout(()=>{
                                  for(let k=0;k>=-250;k--){
                                      document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                                      //document.querySelector('.ssmobilenavbar').style.marginLeft=`${k}px`;
                                  }
                              })
                         // }
                          clk=0; 
                         setTimeout(()=>{
                          document.querySelector('.ssmobilenavbar').style.display='none';
                         },500) 
                          //document.querySelector('.ssmobilenavbar').style.display='none';
                      //}




               }}>x</p>
           </div>
           <br/><br/>
  <div className='profile'>
      <div className='profileOne'>
        <img src={userName} alt=""/>
         <div className='profileInfo'>
          <p style={{marginLeft:'30px',color:'white'}}>{sessionStorage.getItem('userName')}</p>
          <p><img src={phoneNumber} alt=''/> {sessionStorage.getItem('phone')}</p>
          <p style={{marginLeft:'100px',marginTop:'-15px'}}>$ {0}Birr </p>
         </div>
      </div>
    </div>            
           <ul>

                         <li onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}`)}}>
                          HOME 
                        </li>
                          <li onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}/amount/20`)}}>
                            GAME 
                          </li>
          
                            <li  onClick={()=>{document.location=`./profile`}}>
                              Profile
                            </li>
                            <li onClick={()=>{document.location=`./playing-history`}}>
                            Playing History
                            </li>
                            <li onClick={()=>{document.location=`./winning-history`}}>
                             Winning History
                            </li>
                            <li onClick={()=>{document.location=`./transaction-history`}}>
                              Transaction History
                            </li>
                            <li onClick={()=>{document.location=`./message`}}>
                               Message
                            </li>
                          
                           
                        </ul>
                        <button style={{cursor:'pointer'}} onClick={()=>{
                          //sessionStorage.clear()
                           sessionStorage.clear('userName')
                           sessionStorage.clear('phone')
                           navigate('../auth/login')
                        }}>Logout</button>                   
 

                        </div>
                    </div>
        


    </div>)
}

export default PlayingHistory;