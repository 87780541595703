//import Head from '../components/Header'
import userName from '../images/userName.jpg'
import '../styles/profile.css'
import { useNavigate } from 'react-router-dom'
import phoneNumber from '../images/phoneNumber.jpg'
const Myprofile=()=>{
  let clk=0
  const navigate=useNavigate()
    return(<div>
       <div className='profilehead'>
        <img src={userName} alt='' onClick={()=>{
              if(clk===0){
                //document.querySelector('.playlist').style.marginLeft='500px';
                document.querySelector('.ssmobilenavbar').style.display='block';
                //document.querySelector('.shamberger div').style.backgroundColor='gray';
                //for(let n=-250;n<0;n++){
                    setTimeout(()=>{
                        for(let k=-250;k<0;k++){
                            document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                           // document.querySelector('.playlist').style.marginLeft=`${k}px`;
                       }
                    })
                //}
                clk++; 
            }
            else{
        
                //document.querySelector('.playlist').style.marginLeft='10px';
                //document.querySelector('.mobilenavbar').style.display='none';
                //document.querySelector('.shamberger div').style.backgroundColor='rgb(255, 0, 157)';
             //   for(let n=0;n>-250;n--){
                    setTimeout(()=>{
                        for(let k=0;k>-250;k--){
                            document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                           
                        }
                    })
             //   }
                clk=0; 
                document.querySelector('.ssmobilenavbar').style.display='none';
            }


        }}/>
        <h3>{`${sessionStorage.getItem('userName')}`}</h3>
        <h4>{`${sessionStorage.getItem('phone')}`}</h4>
        <h1>Profile</h1> 
        <ul>
        <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}`)}}>HOME</p> 
          </li>
          <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}/amount/20`)}}>GAME</p> 
          </li>
          <li>
          <p >DEPOSIT</p> 
          </li>
          <li>
          <p >BALANCE</p> 
          </li>
          <li>
            <p id='demo' ></p> 
          </li>
          
        </ul>   
       </div>
 <div>      
  <div className='ham' onClick={()=>{
    document.querySelector('.showhi').style.display='block'
  }}>
   <div></div>
   <div></div>
   <div></div>  
  </div>
     <div className='showhii'>
  
      <div className='showhi'>
      <button style={{position:'fixed',padding:'10px',backgroundColor:'black',color:'white'}} onClick={()=>{
        document.querySelector('.showhi').style.display='none'
      }}>x</button>
        <ul>
     <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}`)}}>HOME</p> 
          </li>
          <li>
          <p onClick={()=>{navigate(`../user/${sessionStorage.getItem('userName')}/amount/20`)}}>GAME</p> 
          </li>
          <li>
          <p >DEPOSIT</p> 
          </li>
          <li>
          <p >BALANCE</p> 
          </li>
         </ul> 
        </div>    
    </div> 
 </div>    
     <div className='prof'>
        <h3>Edit Profile</h3>
        <button className='btnr' style={{position:'fixed'}}>{sessionStorage.getItem('userName').slice(0,2)}</button>
        <div className='prok'>
           <p>nic name</p>
           <input placeholder={sessionStorage.getItem('userName')}/>
           <p>phone</p>
           <input placeholder={sessionStorage.getItem('phone')}/>
            <p>Age</p>
           <input placeholder='age'/>
            <p>sex</p>
           <select>
              <option>male</option>
              <option>female</option>
           </select>
            <p>Email</p>
           <input placeholder='email'/>
            <p>Adress</p>
           <input placeholder='adress'/>
           <br/>
           <button className='buton' onClick={()=>{
             document.querySelector('.buton').classList.add('buton--loading')
            // document.querySelector('.singtxt').style.display='none';
      
           }}>Edit profile</button>
        </div>
     </div>



     <div className='ssmobilenavbar'>
  
            <div className='ssmobilenavbarone'>
            <div className='cancle'>
               <p onClick={()=>{
                      /*
                      if(clk===0){
                          //document.querySelector('.playlist').style.marginLeft='500px';
                          document.querySelector('.ssmobilenavbar').style.display='block';
                          //document.querySelector('.shamberger div').style.backgroundColor='gray';
                          for(let n=-250;n<0;n++){
                              setTimeout(()=>{
                                  for(let k=-250;k<=n;k++){
                                      document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                                     // document.querySelector('.playlist').style.marginLeft=`${k}px`;
                                  }
                              })
                          }
                          clk++; 
                      }
                     */ 
                      //else{
                  
                          //document.querySelector('.playlist').style.marginLeft='10px';
                          //document.querySelector('.mobilenavbar').style.display='none';
                          //document.querySelector('.shamberger div').style.backgroundColor='rgb(255, 0, 157)';
                         // for(let n=0;n>-250;n--){
                              setTimeout(()=>{
                                  for(let k=0;k>=-250;k--){
                                      document.querySelector('.ssmobilenavbarone').style.marginLeft=`${k}px`;
                                      //document.querySelector('.ssmobilenavbar').style.marginLeft=`${k}px`;
                                  }
                              })
                         // }
                          clk=0; 
                         setTimeout(()=>{
                          document.querySelector('.ssmobilenavbar').style.display='none';
                         },500) 
                          //document.querySelector('.ssmobilenavbar').style.display='none';
                      //}




               }}>x</p>
           </div>
           <br/><br/>
  <div className='profile'>
      <div className='profileOne'>
        <img src={userName} alt=""/>
         <div className='profileInfo'>
          <p style={{marginLeft:'30px',color:'white'}}>{sessionStorage.getItem('userName')}</p>
          <p><img src={phoneNumber} alt=''/> {sessionStorage.getItem('phone')}</p>
          <p style={{marginLeft:'100px',marginTop:'-15px'}}>$ {sessionStorage.getItem('currentBalance')}Birr </p>
         </div>
      </div>
    </div>            
    <ul>
                            <li  onClick={()=>{document.location=`./profile`}}>
                              Profile
                            </li>
                            <li onClick={()=>{document.location=`./playing-history`}}>
                            Playing History
                            </li>
                            <li onClick={()=>{document.location=`./winning-history`}}>
                             Winning History
                            </li>
                            <li onClick={()=>{document.location=`./transaction-history`}}>
                              Transaction History
                            </li>
                            <li onClick={()=>{document.location=`./message`}}>
                               Message
                            </li>
                            <li onClick={()=>{document.location=`./free-play`}}>
                              free play
                            </li>
                           
                        </ul>
                        <button style={{cursor:'pointer'}} onClick={()=>{
                          //sessionStorage.clear()
                           sessionStorage.clear('userName')
                           sessionStorage.clear('phone')
                           navigate('../auth/login')
                        }}>Logout</button>                   
 

                        </div>
                    </div>
        


    </div>)
}

export default Myprofile;