//import { useState } from 'react'
import glassOne from '../images/glassOne.jpg'
import { useNavigate } from 'react-router-dom'
import authAxois from './requiestHandler'
const AllWinners=()=>{
    const navigate=useNavigate()

    //const {win,setWin}=useState([])
    //const winners=[]
 authAxois.get(`/users/winhistory/info`).then((rs)=>{
    for(let i=0;i<rs.data.length;i++){
        // if(sessionStorage.getItem('phone')===rs.data[i].playerphone){     
             //console.log(rs)
             //his.push(rs.data[i])
             //console.log(phis)
             const tkname=document.createElement('p')
             tkname.textContent=rs.data[i].winnersname
             tkname.style.color='yellow'
             tkname.style.padding='10px'
             tkname.style.borderBottom='1px solid red'
             document.querySelector('.ontk').appendChild(tkname) 

             const tkphone=document.createElement('p')
             tkphone.textContent=rs.data[i].winnersid
             tkphone.style.color='yellow'
             tkphone.style.padding='10px'
             tkphone.style.borderBottom='1px solid red'
             document.querySelector('.ontkk').appendChild(tkphone)

             const tk=document.createElement('p')
             tk.textContent=rs.data[i].winnersdate
             tk.style.color='yellow'
             tk.style.padding='10px'
             tk.style.borderBottom='1px solid red'
             document.querySelector('.ont').appendChild(tk)
 
             const tkn=document.createElement('p')
             tkn.textContent=rs.data[i].winnerstime
             tkn.style.color='yellow'
             tkn.style.padding='10px'
             tkn.style.borderBottom='1px solid red'
             document.querySelector('.twt').appendChild(tkn)
 
             const tkm=document.createElement('p')
             tkm.textContent=rs.data[i].playercard
             tkm.style.color='yellow'
             tkm.style.padding='10px'
             tkm.style.borderBottom='1px solid red'
             document.querySelector('.tht').appendChild(tkm)
 
             const tkl=document.createElement('p')
             tkl.textContent=rs.data[i].medeb
             tkl.style.color='yellow'
             tkl.style.padding='10px'
             tkl.style.borderBottom='1px solid red'
             document.querySelector('.fot').appendChild(tkl)

             const tkll=document.createElement('p')
             tkll.textContent=rs.data[i].winnersprice
             tkll.style.color='yellow'
             tkll.style.padding='10px'
             tkll.style.borderBottom='1px solid red'
             document.querySelector('.fott').appendChild(tkll)
         }
         console.log(rs.data)
         document.querySelector('.btn').innerHTML=`Total Winners\n ${rs.data.length}`
   }).catch((e)=>{console.log(e)})

    return(<div>
        <div className='terr'><p id='demo'></p></div>
        <div className="nimdaheader">
          <div className='user'>
           <img src={glassOne} alt=''/>  
           <h1>Admin</h1> 
         </div>  
            <ul>
                <li onClick={()=>{
                    navigate('../nimdaUser/auth/nimda/allplayers')
                }}>players</li>
                <li>winners</li>
                <li>access</li>
                <li>transactions</li>
                <li>Messages</li>
                <li><input placeholder='Search....' />
                   <button>Search</button>
                </li>
            </ul>
        </div> 
    <div className='allplayersinf'>
        <h1>All winners Info</h1>
        <div className='dude'>
        <div style={{position:'fixed'}}><button className='btn' style={{backgroundColor:'#303030',padding:'10px',color:'white',marginLeft:'1000px',marginTop:'-50px'}}></button></div>
        <div className='ont'></div>
        <div className='ontk'></div>
        <div className='ontkk'></div>
         <div className='twt'></div>
         <div className='tht'></div>
         <div className='fot'></div>
         <div className='fott'></div>
        </div>
    </div>  
    </div>)
}
export default AllWinners;